/* eslint-disable @typescript-eslint/no-non-null-assertion */

import './bus-builder.css';

import { Button, Typography } from '@mui/material';

import { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import BusBuilderAccess from './bus-builder-steps/step-2.access';
import BusBuilderDetails from './bus-builder-steps/step-1.details';
import BusBuilderJustification from './bus-builder-steps/step-4.justification';
import BusBuilderTags from './bus-builder-steps/step-3.tags';
import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { postEventBusApplication } from '../../api/bus-application';

const steps = ['Detail', 'Access', 'Tags', 'Justification'];

export type StepperPayload = {
  eventBusObject: {
    name?: string;
    tags?: {
      events_market?: string;
      events_visibility?: string;
      events_used_for_testing?: string;
      'mps:data-privacy:classification'?: string;
      'mps:data-privacy:personal-data'?: string;
      'mps:data-privacy:credit-card-data'?: string;
      events_applications_producer?: string[];
      events_application_consumer?: string[];
      custom_tags?: Record<string, string> | string[];
      events_tenant_owner?: string;
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
  };
  scope: string;
  accounts?: string[];
  ownerAccountName?: string;
  businessJustification?: string;
};

export interface EventBusBuilderStepProps {
  stepperPayload: StepperPayload;
  setStepperPayload: (stepperPayload: StepperPayload) => void;
  handleBack: () => void;
  handleNext: () => void;
  [key: string]: unknown;
}

export default function EventBusBuilder({
  setModalStatus,
}: {
  setModalStatus: (status: boolean) => void;
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [submissionResult, setSubmissionResult] = React.useState<
    | {
        status: 'success' | 'failure';
        message: string;
      }
    | undefined
  >(undefined);

  const [stepperPayload, setStepperPayload] = React.useState<StepperPayload>({
    eventBusObject: {},
    scope: 'private',
    ownerAccountName: localStorage.getItem('selectedTenant') as string,
  });

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      const payload = stepperPayload;
      // Handle custom tags

      payload.eventBusObject.tags = (
        (payload.eventBusObject.tags?.custom_tags || []) as string[]
      ).reduce((previous, current) => {
        const [key, value] = current.split(':');
        return { ...previous, ...{ [key]: value } };
      }, payload.eventBusObject.tags);
      delete payload.eventBusObject.tags?.custom_tags;
      // Map tags
      payload.eventBusObject.tags = Object.entries<string | string[]>(
        payload.eventBusObject.tags!
      ).map(([key, tag]) => ({
        key,
        value: Array.isArray(tag) ? tag.join('+') : tag,
      }));
      postEventBusApplication(payload)
        .then(() => {
          setSubmissionResult({
            status: 'success',
            message:
              'We have received your request. A BFF team member will review your application. You will receive all the updates via email.',
          });
        })
        .catch((err: AxiosError) => {
          let detail = '';
          if (err.response && err.response.data) {
            detail = (err.response.data as any).message;
          }
          setSubmissionResult({
            status: 'failure',
            message: `${err.message}. ${detail}`,
          });
        });
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {submissionResult ? (
        <Box className="stepper-box">
          <Box>
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {submissionResult.status === 'failure'
                ? 'Request Failed'
                : 'Success'}
            </Typography>
            <Typography variant="body1">{submissionResult.message}</Typography>
          </Box>
          <Box className="control-box">
            <Box className="control-box-separator" />
            <Button onClick={() => setModalStatus(false)}>Close</Button>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h6" component="h4" p="0 0 15px 0">
            Add BUS
          </Typography>
          <Box className="stepper-box">
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Box className="stepper-body">
                {activeStep === 0 ? (
                  <BusBuilderDetails
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 1 ? (
                  <BusBuilderAccess
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 2 ? (
                  <BusBuilderTags
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : (
                  <BusBuilderJustification
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )}
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </>
  );
}
