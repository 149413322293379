import { Box, Button, Divider, Typography } from '@mui/material';

import React from 'react';
import EditorPane from '../../../editor/Editor';
import { RuleBuilderStepProps } from '../rule-builder';

const RuleBuilderOverview: React.FC<RuleBuilderStepProps> = ({
  stepperPayload,
  handleNext,
  handleBack,
}: RuleBuilderStepProps) => {
  const { name, description, eventPattern, targets, retrySchedule } =
    stepperPayload.eventRuleObject;
  const { businessJustification } = stepperPayload;

  return (
    <Box maxHeight="80vh" overflow="auto" padding={2}>
      <Box marginBottom={2}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          Rule Summary
        </Typography>
        <Divider sx={{ margin: '10px' }} />
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Name:
        </Typography>
        <Typography variant="body1">{name}</Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Description:
        </Typography>
        <Typography variant="body1">{description}</Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Guaranteed delivery config:
        </Typography>
        <Typography variant="body1">
          {retrySchedule
            ? retrySchedule > 1
              ? `Every ${retrySchedule} Hours`
              : `Every ${retrySchedule} Hour`
            : 'Default'}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Business justification:
        </Typography>
        <Typography variant="body1">{businessJustification}</Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Event Pattern:
        </Typography>
        <EditorPane height="150px" width="80vh" value={eventPattern} readOnly />
      </Box>

      <Box marginBottom={2}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Targets
        </Typography>
        <Divider sx={{ margin: '10px' }} />
        {Object.values(targets).map((target, index) => (
          <React.Fragment key={index}>
            <Typography variant="h6" gutterBottom>
              <span
                style={{
                  fontWeight: 'bold',
                  marginTop: '5px',
                  textDecoration: target.targetToDelete
                    ? 'Line-through'
                    : 'None',
                }}
              >
                Target {index + 1}:
              </span>
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: 'bold' }}>Target Type:</span>{' '}
              {target.targetType}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: 'bold' }}>Target Name:</span>{' '}
              {target.targetName}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: 'bold' }}>Target Description:</span>{' '}
              {target.targetDescription}
            </Typography>
            {target.httpTargetProps && (
              <React.Fragment>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Endpoint:</span>{' '}
                  {target.httpTargetProps.endpoint}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>HTTP Method:</span>{' '}
                  {target.httpTargetProps.httpMethod}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    Authorization Type:
                  </span>{' '}
                  {target.httpTargetProps.authorizationType}
                </Typography>
                {target.httpTargetProps.apiKeyParams && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>API Key Name:</span>{' '}
                    {target.httpTargetProps.apiKeyParams.apiKeyName}
                  </Typography>
                )}
                {target.httpTargetProps.apiKeyParams && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>API Key Value:</span>{' '}
                    {target.httpTargetProps.apiKeyParams.apiKeyValue}
                  </Typography>
                )}
                {target.httpTargetProps.oauthParams && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>
                      Authorization Endpoint:
                    </span>{' '}
                    {target.httpTargetProps.oauthParams.authorizationEndpoint}
                  </Typography>
                )}
                {target.httpTargetProps.oauthParams && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Client ID:</span>{' '}
                    {target.httpTargetProps.oauthParams.clientId}
                  </Typography>
                )}
                {target.httpTargetProps.oauthParams && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Client Secret:</span>{' '}
                    {target.httpTargetProps.oauthParams.clientSecret}
                  </Typography>
                )}
                {target.httpTargetProps.oauthParams?.username && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Username:</span>{' '}
                    {target.httpTargetProps.oauthParams.username}
                  </Typography>
                )}
                {target.httpTargetProps.oauthParams?.password && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Password:</span>{' '}
                    {target.httpTargetProps.oauthParams.password}
                  </Typography>
                )}
              </React.Fragment>
            )}
            {target.eventBusTargetProps && (
              <React.Fragment>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Event Bus ARN:</span>{' '}
                  {target.eventBusTargetProps.targetEventBusArn}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Event Bus Name:</span>{' '}
                  {target.eventBusTargetProps.targetEventBusName}
                </Typography>
              </React.Fragment>
            )}
            {index < Object.values(targets).length - 1 && (
              <Divider sx={{ margin: '10px' }} />
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box className="control-box">
        <Button onClick={handleBack} color="inherit">
          Back
        </Button>
        <Box className="control-box-separator" />
        <Button onClick={handleNext}>Send</Button>
      </Box>
    </Box>
  );
};

export default RuleBuilderOverview;
