import Box from '@mui/material/Box';
import EditorPane from '../../editor/Editor';
import React from 'react';

type DefineSchemaProps = {
  schema: string;
  handleChange: (value: string | undefined) => void;
};

const DefineSchema: React.FC<DefineSchemaProps> = ({
  schema,
  handleChange,
}) => {
  return (
    <Box sx={{ maxWidth: 1000 }}>
      <EditorPane
        height="45vh"
        width="100%"
        defaultValue={schema}
        value={schema}
        onValueChange={handleChange}
      />
    </Box>
  );
};

export default DefineSchema;
