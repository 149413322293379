import PageLayout from './PageLayout';
import BusAdmin from '../../pages/bus-admin';
import RuleAdmin from '../../pages/rule-admin';
import React from 'react';
import Schemas from './schemas/Schemas';
import { Route, Routes } from 'react-router-dom';

const MainPage: React.FC = () => {
  return (
    <>
      <div
        style={{
          margin: 'auto',
          marginTop: '0%',
          marginBottom: '0%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Routes>
          <Route path="/" Component={PageLayout} />
          <Route path="/schemas" Component={Schemas} />
          <Route path="/bus-admin" Component={BusAdmin} />
          <Route path="/rule-admin" Component={RuleAdmin} />
        </Routes>
      </div>
    </>
  );
};

export default MainPage;
