import {
  ADD_SCHEMAS_TO_BUS,
  AlignmentActions,
  CHANGE_ALIGNMENT,
  LOAD_BUSSES_REQUEST,
  LOAD_BUSSES_SUCCESS,
  LOAD_BUS_REQUEST,
  LOAD_BUS_RULES_REQUEST,
  LOAD_BUS_RULES_SUCCESS,
  LOAD_BUS_SUCCESS,
  LOAD_RULE_FAILED_EVENTS_REQUEST,
  LOAD_RULE_FAILED_EVENTS_RESET,
  LOAD_RULE_FAILED_EVENTS_SUCCESS,
} from '../actions/actionTypes';

import { Bus } from '../../model/Bus';
import { RequestState } from '../../model/requestState';
import { Rule } from '../../model/Rule';
import { FailedEvent } from '../../model/failedEvent';

export type AlignmentState = {
  current: 'bus' | 'schemas';
  busses: Bus[];
  bus?: Bus;
  rules?: Rule[];
  ruleFailedEvents: FailedEvent[];
  requestStateBusses: RequestState;
  requestStateRules: RequestState;
  requestStateRuleFailedEvents: RequestState;
};

const initState: AlignmentState = {
  current: 'bus',
  busses: [],
  rules: [],
  ruleFailedEvents: [],
  requestStateBusses: RequestState.INITIAL,
  requestStateRules: RequestState.INITIAL,
  requestStateRuleFailedEvents: RequestState.INITIAL,
};

export default (
  state: AlignmentState = initState,
  action: AlignmentActions
) => {
  switch (action.type) {
    case CHANGE_ALIGNMENT:
      return {
        ...state,
        current: action.alignment,
      };
    case LOAD_BUSSES_REQUEST: {
      return {
        ...state,
        requestStateBusses: action.requestStateBusses,
      };
    }
    case LOAD_BUSSES_SUCCESS: {
      return {
        ...state,
        busses: action.busses,
        requestStateBusses: action.requestStateBusses,
      };
    }
    case LOAD_BUS_REQUEST: {
      return {
        ...state,
        rules: [],
        requestStateBusses: action.requestStateBusses,
      };
    }
    case LOAD_BUS_SUCCESS: {
      return {
        ...state,
        bus: action.bus,
        requestStateBusses: action.requestStateBusses,
      };
    }
    case LOAD_BUS_RULES_REQUEST: {
      return {
        ...state,
        rules: [],
        requestStateRules: action.requestStateRules,
      };
    }
    case LOAD_BUS_RULES_SUCCESS: {
      const newState = {
        ...state,
        rules: action.rules,
        requestStateRules: action.requestStateRules,
      };
      return newState;
    }
    case LOAD_RULE_FAILED_EVENTS_REQUEST: {
      return {
        ...state,
        ruleFailedEvents: [],
        requestStateRuleFailedEvents: action.requestStateRuleFailedEvents,
      };
    }
    case LOAD_RULE_FAILED_EVENTS_SUCCESS: {
      const newState = {
        ...state,
        ruleFailedEvents: action.failedEvents,
        requestStateRuleFailedEvents: action.requestStateRuleFailedEvents,
      };
      return newState;
    }
    case LOAD_RULE_FAILED_EVENTS_RESET: {
      const newState = {
        ...state,
        ruleFailedEvents: [],
        requestStateRuleFailedEvents: RequestState.INITIAL,
      };
      return newState;
    }
    case ADD_SCHEMAS_TO_BUS:
      return {
        ...state,
        bus: action.bus,
      };
    default:
      return state;
  }
};
