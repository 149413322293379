import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadRuleFailedEvents } from '../../../redux/actions/AlignmentActions';
import { RequestState } from '../../../model/requestState';
import { Info, Refresh } from '@mui/icons-material';
import { LOAD_RULE_FAILED_EVENTS_RESET } from '../../../redux/actions/actionTypes';

const spinnerStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '40px',
  marginBottom: '20px',
};
const tableHeaderColor = 'lightgrey';
const tableCellStyle = (index: number, expandedRowIndex: number | null) => ({
  // This hides the row separator when a row is expanded, because it would look weird
  borderBottom: index === expandedRowIndex ? 'none' : undefined,
});
const nestedTableBackgroundColor = '#E9F1FC'; // Light blue
const infoText =
  'This list shows up to 10 failed events that will be resent according to your specified schedule. Please note that for technical reasons it may not display 10 events for some cases, even if those are queued for resending. Since the AWS API to receive messages has a delay and is non-deterministic, please refresh this dialog and wait up to 5 minutes.';
const retryAttemptsInfoText =
  'This is the number of attempts that EventBridge performed to deliver the event during the last "retry cycle". It does not describe the number of times that the custom guaranteed delivery mechanism resent your event to EventBridge.';

type Props = {
  eventBusName: string;
  ruleName: string;
  handleCloseFailedEvents: () => void;
};

type SortableColumnField =
  | 'targetArn'
  | 'errorCode'
  | 'errorMessage'
  | 'exhaustedRetryCondition';

export const FailedEventsOverview = ({
  eventBusName,
  ruleName,
  handleCloseFailedEvents,
}: Props) => {
  const [sortColumn, setSortColumn] =
    useState<SortableColumnField>('targetArn');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const failedEvents = useAppSelector(
    (state) => state.alignment.ruleFailedEvents
  );
  const requestStateRuleFailedEvents = useAppSelector(
    (state) => state.alignment.requestStateRuleFailedEvents
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (requestStateRuleFailedEvents === RequestState.INITIAL) {
      dispatch(loadRuleFailedEvents(eventBusName, ruleName));
    }
  }, [requestStateRuleFailedEvents]);

  useEffect(() => {
    return () => {
      dispatch({ type: LOAD_RULE_FAILED_EVENTS_RESET });
    };
  }, []);

  const handleRowExpand = async (index: number) => {
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleRefresh = () => {
    dispatch(loadRuleFailedEvents(eventBusName, ruleName));
  };

  const handleSort = (column: SortableColumnField) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedEvents = [...failedEvents].sort((a, b) => {
    if (sortColumn) {
      const comparison = a[sortColumn].localeCompare(b[sortColumn]);
      return sortOrder === 'asc' ? comparison : -comparison;
    }
    return 0;
  });

  const renderPage = () => {
    if (
      requestStateRuleFailedEvents === RequestState.INITIAL ||
      requestStateRuleFailedEvents === RequestState.FETCHING
    ) {
      return (
        <Box style={spinnerStyle}>
          <CircularProgress />
        </Box>
      );
    } else {
      if (sortedEvents.length) {
        return (
          <TableContainer style={{ borderRadius: '5px' }}>
            <Table style={{ border: `1px solid ${tableHeaderColor}` }}>
              <TableHead>
                <TableRow style={{ backgroundColor: tableHeaderColor }}>
                  <TableCell></TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortColumn === 'targetArn'}
                      direction={sortColumn === 'targetArn' ? sortOrder : 'asc'}
                      onClick={() => handleSort('targetArn')}
                    >
                      <strong>Target</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortColumn === 'errorCode'}
                      direction={sortColumn === 'errorCode' ? sortOrder : 'asc'}
                      onClick={() => handleSort('errorCode')}
                    >
                      <strong>Error Code</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortColumn === 'exhaustedRetryCondition'}
                      direction={
                        sortColumn === 'exhaustedRetryCondition'
                          ? sortOrder
                          : 'asc'
                      }
                      onClick={() => handleSort('exhaustedRetryCondition')}
                    >
                      <strong>Reason</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortColumn === 'errorMessage'}
                      direction={
                        sortColumn === 'errorMessage' ? sortOrder : 'asc'
                      }
                      onClick={() => handleSort('errorMessage')}
                    >
                      <strong>Error Message</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <strong>Details</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedEvents.map((event, index) => (
                  <React.Fragment key={index}>
                    <TableRow hover onClick={() => handleRowExpand(index)}>
                      <TableCell
                        style={tableCellStyle(index, expandedRowIndex)}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={tableCellStyle(index, expandedRowIndex)}
                      >
                        {event.targetArn.split(':').slice(5)}
                      </TableCell>
                      <TableCell
                        style={tableCellStyle(index, expandedRowIndex)}
                      >
                        {event.errorCode}
                      </TableCell>
                      <TableCell
                        style={tableCellStyle(index, expandedRowIndex)}
                      >
                        {event.exhaustedRetryCondition}
                      </TableCell>
                      <TableCell
                        style={tableCellStyle(index, expandedRowIndex)}
                      >
                        {`${event.errorMessage.substring(0, 50)}...`}
                      </TableCell>
                      <TableCell
                        style={tableCellStyle(index, expandedRowIndex)}
                      >
                        <IconButton>
                          {expandedRowIndex === index ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                      <TableCell
                        style={{
                          padding: '0px 30px 0px 30px',
                          borderBottom: 'none',
                        }}
                        colSpan={6}
                      >
                        <Collapse
                          in={expandedRowIndex === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Table
                            style={{
                              backgroundColor: nestedTableBackgroundColor,
                              borderRadius: '5px',
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <strong>Attribute</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Value</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Body</TableCell>
                                <TableCell>
                                  {JSON.stringify(event.body)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>ERROR_CODE</TableCell>
                                <TableCell>{event.errorCode}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>ERROR_MESSAGE</TableCell>
                                <TableCell>{event.errorMessage}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ padding: '0 0 0 16px' }}>
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    RETRY_ATTEMPTS
                                    <Tooltip title={retryAttemptsInfoText}>
                                      <IconButton aria-label="info">
                                        <Info />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </TableCell>
                                <TableCell>{event.retryAttempts}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>EXHAUSTED_RETRY_CONDITION</TableCell>
                                <TableCell>
                                  {event.exhaustedRetryCondition}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>RULE_ARN</TableCell>
                                <TableCell>{event.ruleArn}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: 'none' }}>
                                  TARGET_ARN
                                </TableCell>
                                <TableCell style={{ borderBottom: 'none' }}>
                                  {event.targetArn}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      } else {
        return (
          <Typography align="center" sx={{ my: 2 }}>
            There are currently no failed events queued for resending.
          </Typography>
        );
      }
    }
  };

  return (
    <Box>
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" component="h2" align="left">
          <strong>{`Failed Events for ${ruleName}`}</strong>
        </Typography>
        <Box style={{ display: 'flex' }}>
          <IconButton aria-label="info" onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        </Box>
      </Grid>
      <Box sx={{ p: 1, marginBottom: '20px' }}>
        <Typography variant="body2" align="left">
          {infoText}
        </Typography>
      </Box>

      {renderPage()}

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{ marginTop: '20px', marginLeft: 'auto' }}
          variant="contained"
          onClick={handleCloseFailedEvents}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};
