/** @format */

import React from 'react';
import { Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { getStageConfig } from '../../config/portal';

const Login: React.FC = () => {
  return (
    <Container>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h6" component="span" mt={1}>
          Sign-In Required
        </Typography>
        <p>You need to be signed in to use the Event Portal.</p>
      </div>
      <Button
        style={{ margin: 'auto' }}
        fullWidth
        variant="contained"
        color="primary"
        href={`${getStageConfig().authUrl}/auth/login?loginRedirectUrl=${
          window.location.href
        }`}
        id="login"
      >
        Sign In
      </Button>
    </Container>
  );
};

export default Login;
