import {
  CommonActions,
  FETCH_MPS_TENANTS_FAILED,
  FETCH_MPS_TENANTS_REQUESTED,
  FETCH_MPS_TENANTS_SUCCEEDED,
} from '../actions/actionTypes';

import { RequestState } from '../../model/requestState';

export type CommonState = {
  mpsTenants: string[];
  requestStateMpsTenants: RequestState;
};

const initState: CommonState = {
  mpsTenants: [],
  requestStateMpsTenants: RequestState.INITIAL,
};

export default (state: CommonState = initState, action: CommonActions) => {
  switch (action.type) {
    case FETCH_MPS_TENANTS_REQUESTED: {
      return {
        ...state,
        requestStateBusses: action.requestStateMpsTenants,
      };
    }
    case FETCH_MPS_TENANTS_SUCCEEDED: {
      return {
        ...state,
        mpsTenants: action.mpsTenants,
        requestStateBusses: action.requestStateMpsTenants,
      };
    }
    case FETCH_MPS_TENANTS_FAILED: {
      return {
        ...state,
        mpsTenants: [],
        requestStateBusses: action.requestStateMpsTenants,
      };
    }
    default:
      return state;
  }
};
