import { RootState, ThunkAction } from '../store';
import {
  BusActions,
  DELETE_BUS_FAILED,
  DELETE_BUS_REQUEST,
  DELETE_BUS_RESET,
  DELETE_BUS_SUCCESS,
} from './actionTypes';
import { RequestState } from '../../model/requestState';
import { deleteBusByName } from '../../api/client';
import { loadBusses } from './AlignmentActions';

export const deleteBus =
  (
    busName: string,
    ownerAccountName: string
  ): ThunkAction<void, RootState, unknown, BusActions> =>
  async (dispatch) => {
    dispatch({
      type: DELETE_BUS_REQUEST,
      requestStateDeleteBus: RequestState.FETCHING,
    });
    try {
      await deleteBusByName(busName, ownerAccountName);
      dispatch({
        type: DELETE_BUS_SUCCESS,
        requestStateDeleteBus: RequestState.SUCCESS,
      });
      dispatch(loadBusses(ownerAccountName));
      dispatch({
        type: DELETE_BUS_RESET,
      });
    } catch (err: any) {
      let detailedMessage = '';
      if (err.response && err.response.data && err.response.data.message) {
        detailedMessage = err.response.data.message;
      }
      dispatch({
        type: DELETE_BUS_FAILED,
        requestStateDeleteBus: RequestState.ERROR,
        error: `${err.message}. ${detailedMessage}`,
      });
    }
  };
