import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import SchemaList from './SchemaList';
import { getSchemasByBus } from '../../../api/client';
import { useAppSelector } from '../../../hooks';

const Schemas = () => {
  const [schemas, setSchemas] = useState([]);
  const registry = useAppSelector((state) => state.alignment.bus?.name || '');
  const schemaNames = useAppSelector(
    (state) => state.alignment.bus?.schemas || ''
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedTenant =
          window.localStorage.getItem('selectedTenant') ?? '';
        const fetchedSchemas = await getSchemasByBus(selectedTenant, registry);
        setSchemas(fetchedSchemas);
      } catch (error) {
        console.error('Error fetching schemas:', error);
      }
    };

    fetchData();
  }, [registry, schemaNames]);

  return (
    <Box sx={{ ml: '5%', width: '90vw' }}>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50%',
          margin: '20px',
        }}
      >
        <CardContent>
          <Typography variant="body1">
            Below you find all schemas created for the event bus{' '}
            <strong>{registry}</strong>. To create a new schema, please select
            the corresponding event bus and then click on the <strong>+</strong>{' '}
            icon.
          </Typography>
        </CardContent>
      </Card>
      <SchemaList schemas={schemas} />
    </Box>
  );
};

export default Schemas;
