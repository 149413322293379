import { AppBar, Chip, IconButton, Toolbar, Typography } from '@mui/material';
import {
  ChangeAlignment,
  changeBus,
  loadBusses,
} from '../../redux/actions/AlignmentActions';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../hooks';

import AddCircle from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import { Bus } from '../../model/Bus';
import EventBusBuilder from '../bus/bus-builder';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import { RequestState } from '../../model/requestState';
import SchemaAdd from '../page-layout/schemas/SchemaAdd';
import { getQuotas } from '../../api/client';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const navigate = useNavigate();

  const [quotas, setQuotas] = useState({
    registries: 0,
  });
  const alignment = useAppSelector((state) => state.alignment.current);
  const busses = useAppSelector<Bus[]>((state) => state.alignment.busses);
  const requestState = useAppSelector(
    (state) => state.alignment.requestStateBusses
  );

  const dispatch = useAppDispatch();
  const [selectedBus, setSelectedBus] = React.useState<Bus | undefined>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (quotas.registries) setOpen(true);
    else
      alert(
        'It is impossible to create any more event buses in BFF platform at the moment. Please reach out to BFF.'
      );
  };
  const handleClose = () => setOpen(false);
  const handleCloseAfterCreation = () => {
    setTimeout(handleClose, 3000);
  };

  const handleBusChange = (event: SelectChangeEvent) => {
    setSelectedBus(busses.find((bus) => bus.name === event?.target?.value));
  };

  useEffect(() => {
    if (busses.length > 0 && !selectedBus) {
      setSelectedBus(busses[0]);
    } else if (selectedBus && busses.includes(selectedBus)) {
      dispatch(
        changeBus(selectedBus, localStorage.getItem('selectedTenant') || '')
      );
    } else {
      setSelectedBus(undefined);
    }
  }, [selectedBus, busses, dispatch]);

  useEffect(() => {
    if (requestState === RequestState.INITIAL) {
      dispatch(loadBusses(localStorage.getItem('selectedTenant') || ''));
    }
  }, [dispatch, requestState]);
  useEffect(() => {
    getQuotas().then((res) => {
      setQuotas(res);
    });
  }, []);

  const styleBus = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const styleSchema = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const theme = createTheme({
    palette: {
      // light: {
      //   main: "#fff",
      // },
    },
  });
  const handleAlignment = (event: SelectChangeEvent) => {
    if (event.target?.value === 'bus' || event.target?.value === 'schemas') {
      navigate(event.target?.value === 'bus' ? '/' : '/schemas');
      dispatch(ChangeAlignment(event.target.value));
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Typography sx={{ paddingLeft: 3 }}>
            Total number of buses left: {quotas.registries}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Select
              labelId="bus-label"
              id="bus-id"
              value={alignment}
              label="alignment"
              className="select-md"
              onChange={handleAlignment}
              style={{ color: 'white' }}
              sx={{
                mr: 1,
                height: '2.5rem',
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
              autoWidth
            >
              <MenuItem value={'bus'}>Event Bus</MenuItem>
              <MenuItem value={'schemas'}>Schemas</MenuItem>
            </Select>
            <Select
              labelId="bus-label"
              id="bus-id"
              value={selectedBus ? selectedBus.name : ''}
              label="custom bus"
              onChange={handleBusChange}
              color="primary"
              className="select-md"
              autoWidth
              style={{ color: 'white' }}
              sx={{
                mr: 1,
                height: '2.5rem',
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            >
              {busses.map((bus: any) => (
                <MenuItem key={bus.name} value={bus.name}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <div>{bus.name}</div>
                    <Chip
                      label={
                        bus.tags.find(
                          (tag: any) => tag.key === 'events_visibility'
                        ).value
                      }
                      color={
                        bus.tags.find(
                          (tag: any) =>
                            tag.key === 'events_visibility' &&
                            tag.value === 'public'
                        )
                          ? 'success'
                          : 'warning'
                      }
                      sx={{ marginLeft: '5px' }}
                    />
                  </div>
                </MenuItem>
              ))}
            </Select>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleOpen}
            >
              <AddCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {alignment === 'bus' ? (
          <Box sx={styleBus}>
            <EventBusBuilder setModalStatus={setOpen} />
          </Box>
        ) : (
          <Box sx={styleSchema}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Schema
            </Typography>
            <SchemaAdd creationClose={handleCloseAfterCreation} />
          </Box>
        )}
      </Modal>
    </ThemeProvider>
  );
};

export default Header;
