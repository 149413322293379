import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { object as zObject, string as zString, TypeOf as zTypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { RuleBuilderStepProps } from '../rule-builder';

// Create a schema and the type for our form
const formConfigSchema = (existingRuleNames?: string[]) =>
  zObject({
    name: zString()
      .min(1, 'Name is required')
      .max(64, 'A name must contain at most 64 character(s)')
      .regex(/^([.\-_A-Za-z0-9]+)$/, 'Please provide an AWS-compliant name')
      .refine(
        (name) => !existingRuleNames?.includes(name),
        'A rule with this name already exists for the bus'
      ),
    description: zString().optional(),
  });
type FormConfig = zTypeOf<ReturnType<typeof formConfigSchema>>;

const RuleBuilderDetails = ({
  stepperPayload,
  setStepperPayload,
  handleNext,
  existingRuleNames,
}: RuleBuilderStepProps) => {
  // Get all form handlers via useForm
  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<FormConfig>({
    defaultValues: {
      name: stepperPayload.eventRuleObject.name,
      description: stepperPayload.eventRuleObject.description,
    },
    resolver: zodResolver(formConfigSchema(existingRuleNames)),
    mode: 'onChange',
  });

  const onNext: SubmitHandler<FormConfig> = ({ name, description }) => {
    stepperPayload.eventRuleObject.name = name;
    stepperPayload.eventRuleObject.description = description;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Box>
          <TextField
            fullWidth
            label="Rule name"
            required
            error={!!errors.name}
            helperText={errors.name?.message ?? ' '}
            defaultValue={defaultValues?.name}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Rule description"
            error={!!errors.description}
            helperText={errors.description?.message ?? ' '}
            defaultValue={defaultValues?.description}
            {...register('description')}
          />
        </Box>
        <Box className="control-box">
          <Box className="control-box-separator" />
          <Button type="submit">Next</Button>
        </Box>
      </Box>
    </>
  );
};

export default RuleBuilderDetails;
