/* eslint-disable @typescript-eslint/no-non-null-assertion */

import './rule-builder.css';

import { Button, Typography } from '@mui/material';
import { RuleStepperPayload, TargetRequest } from '../../page-layout/RuleNode';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { AxiosError } from 'axios';
import React from 'react';
import {
  listEventRuleApplications,
  postEventRuleApplication,
} from '../../../api/rule-application';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import RuleBuilderPatterns from './rule-builder-steps/step-2.patterns';
import RuleBuilderTargets from './rule-builder-steps/step-3.targets';
import RuleSchedule from './rule-builder-steps/step-4.schedule';
import RuleBuilderJustification from './rule-builder-steps/step-5.justification';
import RuleBuilderOverview from './rule-builder-steps/step-6.overview';

const steps = [
  'Update event pattern',
  'Update target(s)',
  'Configure guaranteed delivery',
  'Provide Business Justification',
  'Review and update',
];

export type Rule = {
  name: string;
  eventPattern: string;
  state: string;
  eventBusName: string;
  targets: TargetRequest[];
};

export interface RuleBuilderStepProps {
  stepperPayload: RuleStepperPayload;
  setStepperPayload: (stepperPayload: RuleStepperPayload) => void;
  handleBack: () => void;
  handleNext: () => void;
  [key: string]: unknown;
}

export default function RuleUpdater({
  setModalStatus,
  rule,
}: {
  setModalStatus: (status: boolean) => void;
  rule: RuleStepperPayload;
}) {
  const busses = useAppSelector((state) => state.alignment.busses);
  const [activeStep, setActiveStep] = React.useState(0);
  const [submissionResult, setSubmissionResult] = React.useState<
    | {
        status: 'success' | 'failure';
        message: string;
      }
    | undefined
  >(undefined);

  const [stepperPayload, setStepperPayload] =
    React.useState<RuleStepperPayload>(rule);

  const getOwner = (busName: string) => {
    const bus = busses.find((bus) => bus.name === busName);
    return bus?.tags.find((tag) => tag.key === 'events_creator')?.value || '';
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Transform targets Record to targets Array first
      type StepperPayloadFinal = Omit<RuleStepperPayload, 'eventRuleObject'> & {
        eventRuleObject: Omit<
          typeof stepperPayload.eventRuleObject,
          'targets'
        > & {
          targets: TargetRequest[] | Record<string, TargetRequest>;
        };
      };
      const _stepperPayload: StepperPayloadFinal = stepperPayload;
      _stepperPayload.eventRuleObject.targets = Object.values(
        stepperPayload.eventRuleObject.targets
      );
      postEventRuleApplication(_stepperPayload)
        .then((res) => {
          localStorage.setItem(
            'rule-applications',
            JSON.stringify([
              ...JSON.parse(localStorage.getItem('rule-applications') || '[]'),
              res.id,
            ])
          );

          setSubmissionResult({
            status: 'success',
            message: `Your request has been submitted and is waiting for approval. Please contact an approver of the hosting tenant ${
              stepperPayload.ownerAccountName
            } or the bus owner ${getOwner(
              stepperPayload.eventRuleObject.eventBusName || ''
            )}. You will receive all the updates via email.`,
          });
        })
        .catch((err: AxiosError<{ message: string }>) => {
          let detail = '';
          if (err.response && err.response.data) {
            detail = err.response.data.message;
          }
          const message =
            err.response?.status === 401
              ? `${err.message}. It looks like your access token has expired. Your progress is saved (excluding the sensitive data), you can refresh the page and submit it again.`
              : `${err.message}. ${detail}`;
          setSubmissionResult({
            status: 'failure',
            message,
          });
        });
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {submissionResult ? (
        <Box className="stepper-box">
          <Box>
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {submissionResult.status === 'failure'
                ? 'Request Failed'
                : 'Success'}
            </Typography>
            <Typography variant="body1">{submissionResult.message}</Typography>
          </Box>
          <Box className="control-box">
            <Box className="control-box-separator" />
            <Button onClick={() => setModalStatus(false)}>Close</Button>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h6" component="h4" p="0 0 15px 0">
            Update Rule {rule.eventRuleObject.name}
          </Typography>
          <Box className="stepper-box">
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Box className="stepper-body">
                {activeStep === 0 ? (
                  <RuleBuilderPatterns
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={undefined as any}
                    handleNext={handleNext}
                  />
                ) : activeStep === 1 ? (
                  <RuleBuilderTargets
                    maxAllowedTargets={5}
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 2 ? (
                  <RuleSchedule
                    maxAllowedTargets={5}
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 3 ? (
                  <RuleBuilderJustification
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : (
                  <RuleBuilderOverview
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )}
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </>
  );
}
