/* eslint-disable @typescript-eslint/no-non-null-assertion */

import './bus-builder.css';

import { Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import BusBuilderAccess from './bus-builder-steps/step-2.access';
import BusBuilderTags from './bus-builder-steps/step-3.tags';
import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { StepperPayload } from './bus-builder';
import { loadBusses } from '../../redux/actions/AlignmentActions';
import { patchBusByName } from '../../api/client';

const steps = ['Access', 'Tags', 'Update'];

export default function EventBusUpdater({
  setModalStatus,
}: {
  setModalStatus: (status: boolean) => void;
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [submissionResult, setSubmissionResult] = React.useState<
    | {
        status: 'success' | 'failure';
        message: string;
      }
    | undefined
  >(undefined);

  const dispatch = useAppDispatch();

  const handleUpdate = (busName: string, payload: StepperPayload) => {
    const body: any = {
      eventBusObject: { tags: payload.eventBusObject.tags },
      scope: payload.scope,
      accounts: payload.accounts,
    };

    // transform tags to have the correct backend structure
    body.eventBusObject.tags = (
      (body.eventBusObject.tags?.custom_tags || []) as string[]
    ).reduce((previous, current) => {
      const [key, value] = current.split(':');
      return { ...previous, ...{ [key]: value } };
    }, body.eventBusObject.tags);

    delete body.eventBusObject.tags?.custom_tags;
    body.eventBusObject.tags = Object.entries<string | string[]>(
      body.eventBusObject.tags!
    ).map(([key, tag]) => ({
      key,
      value: Array.isArray(tag) ? tag.join('+') : tag,
    }));

    patchBusByName(busName, body, payload.ownerAccountName || '')
      .then(() => {
        setSubmissionResult({
          status: 'success',
          message: 'Your Bus has successfully been updated.',
        });
      })
      .catch((err: AxiosError) => {
        let detail = '';
        if (err.response && err.response.data) {
          detail = (err.response.data as any).message;
        }
        setSubmissionResult({
          status: 'failure',
          message: `${err.message}. ${detail}`,
        });
      })
      .finally(() => {
        for (let i = 0; i++; i < 20) {
          dispatch(
            loadBusses(window.localStorage.getItem('selected_tenant') || '')
          );
        }
      });
  };

  const busses = useAppSelector((state) => state.alignment.busses);
  const currentBus = useAppSelector((state) => state.alignment.bus);

  const mapTags = (tags: any, customTags: any, nonCustomTags: any) => {
    const nonCustomKeys = [
      'events_market',
      'events_visibility',
      'events_used_for_testing',
      'mps:data-privacy:classification',
      'mps:data-privacy:personal-data',
      'mps:data-privacy:credit-card-data',
      'events_applications_producer',
      'events_application_consumer',
      'events_access_tenants',
      'events_creator',
      'events_tenant_owner',
    ];
    return tags.forEach((tag: any) => {
      if (nonCustomKeys.includes(tag.key)) {
        nonCustomTags.push(tag);
      } else {
        customTags.push(tag);
      }
    });
  };

  const getInitialPayload = (): StepperPayload => {
    const busWithTags = busses.find((bus) => bus.name === currentBus?.name);
    const customTags: any[] = [];
    const nonCustomTags: any[] = [];
    mapTags(busWithTags?.tags, customTags, nonCustomTags);

    const tagObject =
      nonCustomTags.reduce((result, tag) => {
        result[tag.key] = tag.value;
        return result;
      }, {} as any) || {};

    if (customTags.length) {
      tagObject.custom_tags = customTags.map(
        (tag) => `${tag.key}:${tag.value}`
      );
    }
    const scope = tagObject.events_visibility || 'private';
    const ownerAccountName =
      tagObject.events_tenant_owner ||
      (localStorage.getItem('selectedTenant') as string);
    const accounts = tagObject.events_access_tenants?.split(':') || [];
    tagObject.events_applications_producer =
      tagObject.events_applications_producer?.split('+') || [];
    tagObject.events_application_consumer =
      tagObject.events_application_consumer?.split('+') || [];
    delete tagObject.events_access_tenants;
    return {
      eventBusObject: {
        name: currentBus?.name || '',
        tags: tagObject,
      },
      scope,
      ownerAccountName,
      accounts,
    };
  };

  const [stepperPayload, setStepperPayload] = React.useState<StepperPayload>(
    getInitialPayload() || {
      eventBusObject: {}, // get the tags here
      scope: 'private',
      ownerAccountName: localStorage.getItem('selectedTenant') as string,
    }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {submissionResult ? (
        <Box className="stepper-box">
          <Box>
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {submissionResult.status === 'failure'
                ? 'Request Failed'
                : 'Success'}
            </Typography>
            <Typography variant="body1">{submissionResult.message}</Typography>
          </Box>
          <Box className="control-box">
            <Box className="control-box-separator" />
            <Button onClick={() => setModalStatus(false)}>Close</Button>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h6" component="h4" p="0 0 15px 0">
            Update {currentBus?.name}
          </Typography>
          <Box className="stepper-box">
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Box className="stepper-body">
                {activeStep === 0 ? (
                  <BusBuilderAccess
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    firstStep={true}
                  />
                ) : activeStep === 1 ? (
                  <BusBuilderTags
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayload}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p={3}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={4}
                  >
                    <Typography variant="h5">
                      Do you confirm the updates of {currentBus?.name}?
                    </Typography>
                    <Box mt={2} sx={{ '& > button': { marginRight: '8px' } }}>
                      <Button variant="contained" onClick={handleBack}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setModalStatus(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleUpdate(currentBus?.name || '', stepperPayload)
                        }
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </>
  );
}
