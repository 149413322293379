/** @format */

import { getMpsTenants } from '../../api/client';
import { RootState, ThunkAction } from '../store';
import {
  CommonActions,
  FETCH_MPS_TENANTS_REQUESTED,
  FETCH_MPS_TENANTS_SUCCEEDED,
} from './actionTypes';
import { RequestState } from '../../model/requestState';

export const loadMpsTenants =
  (): ThunkAction<void, RootState, unknown, CommonActions> =>
  async (dispatch) => {
    dispatch({
      type: FETCH_MPS_TENANTS_REQUESTED,
      requestStateMpsTenants: RequestState.FETCHING,
    });
    const mpsTenants = await getMpsTenants();
    dispatch({
      type: FETCH_MPS_TENANTS_SUCCEEDED,
      requestStateMpsTenants: RequestState.SUCCESS,
      mpsTenants,
    });
  };
