/** @format */

import { Bus } from '../../model/Bus';
import { FailedEvent } from '../../model/failedEvent';
import { RequestState } from '../../model/requestState';
import { Rule } from '../../model/Rule';

export const FETCH_USER_INFO_REQUESTED = 'FETCH_USER_INFO_REQUESTED';
export const FETCH_USER_INFO_SUCCEEDED = 'FETCH_USER_INFO_SUCCEEDED';
export const FETCH_USER_INFO_FAILED = 'FETCH_USER_INFO_FAILED';

// Auth Actions

export type FetchUserInfoRequestAction = {
  type: typeof FETCH_USER_INFO_REQUESTED;
  requestState: RequestState;
};

export type FetchUserInfoSuccessAction = {
  type: typeof FETCH_USER_INFO_SUCCEEDED;
  requestState: RequestState;
  userinfo?: {
    email: string | undefined;
    groups: [];
  };
};

export type FetchUserInfoFailedAction = {
  type: typeof FETCH_USER_INFO_FAILED;
  requestState: RequestState;
};

export type AuthActions =
  | FetchUserInfoRequestAction
  | FetchUserInfoSuccessAction
  | FetchUserInfoFailedAction;

// Alignment Actions

export const LOAD_BUSSES_REQUEST = 'LOAD_BUSSES_REQUEST';
export const LOAD_BUSSES_SUCCESS = 'LOAD_BUSSES_SUCCESS';

export const LOAD_BUS_REQUEST = 'LOAD_BUS_REQUEST';
export const LOAD_BUS_SUCCESS = 'LOAD_BUS_SUCCESS';

export const LOAD_BUS_RULES_REQUEST = 'LOAD_BUS_RULES_REQUEST';
export const LOAD_BUS_RULES_SUCCESS = 'LOAD_BUS_RULES_SUCCESS';

export const LOAD_RULE_FAILED_EVENTS_REQUEST =
  'LOAD_RULE_FAILED_EVENTS_REQUEST';
export const LOAD_RULE_FAILED_EVENTS_SUCCESS =
  'LOAD_RULE_FAILED_EVENTS_SUCCESS';
export const LOAD_RULE_FAILED_EVENTS_RESET = 'LOAD_RULE_FAILED_EVENTS_RESET';

export const CHANGE_ALIGNMENT = 'CHANGE_ALIGNMENT';
export const ADD_SCHEMAS_TO_BUS = 'ADD_SCHEMAS_TO_BUS';

export type ChangeAlignmentAction = {
  type: typeof CHANGE_ALIGNMENT;
  alignment: 'bus' | 'schemas';
};

export type FetchBussesRequestAction = {
  type: typeof LOAD_BUSSES_REQUEST;
  requestStateBusses: RequestState;
};

export type FetchBusRequestAction = {
  type: typeof LOAD_BUS_REQUEST;
  requestStateBusses: RequestState;
};

export type FetchBusSuccessAction = {
  type: typeof LOAD_BUS_SUCCESS;
  requestStateBusses: RequestState;
  bus: Bus;
};

export type FetchBussesSuccessAction = {
  type: typeof LOAD_BUSSES_SUCCESS;
  requestStateBusses: RequestState;
  busses: Bus[];
};

export type FetchBusRulesRequestAction = {
  type: typeof LOAD_BUS_RULES_REQUEST;
  requestStateRules: RequestState;
};

export type FetchRuleFailedEventsRequestAction = {
  type: typeof LOAD_RULE_FAILED_EVENTS_REQUEST;
  requestStateRuleFailedEvents: RequestState;
};

export type FetchRuleFailedEventsSuccessAction = {
  type: typeof LOAD_RULE_FAILED_EVENTS_SUCCESS;
  requestStateRuleFailedEvents: RequestState;
  failedEvents: FailedEvent[];
};

export type FetchRuleFailedEventsResetAction = {
  type: typeof LOAD_RULE_FAILED_EVENTS_RESET;
};

export type FetchBusRulesSuccessAction = {
  type: typeof LOAD_BUS_RULES_SUCCESS;
  requestStateRules: RequestState;
  rules: Rule[];
};

export type AddSchemasToBusAction = {
  type: typeof ADD_SCHEMAS_TO_BUS;
  requestStateRules: RequestState;
  bus: Bus;
};

export type AlignmentActions =
  | ChangeAlignmentAction
  | FetchBussesRequestAction
  | FetchBussesSuccessAction
  | FetchBusRequestAction
  | FetchBusSuccessAction
  | FetchBusRulesRequestAction
  | FetchRuleFailedEventsRequestAction
  | FetchRuleFailedEventsSuccessAction
  | FetchRuleFailedEventsResetAction
  | FetchBusRulesSuccessAction
  | AddSchemasToBusAction;

// Bus Actions

export const DELETE_BUS_REQUEST = 'DELETE_BUS_REQUEST';
export const DELETE_BUS_SUCCESS = 'DELETE_BUS_SUCCESS';
export const DELETE_BUS_FAILED = 'DELETE_BUS_FAILED';
export const DELETE_BUS_RESET = 'DELETE_BUS_RESET';

export type DeleteBusRequestAction = {
  type: typeof DELETE_BUS_REQUEST;
  requestStateDeleteBus: RequestState;
};

export type DeleteBusSuccessAction = {
  type: typeof DELETE_BUS_SUCCESS;
  requestStateDeleteBus: RequestState;
};

export type DeleteBusFailedAction = {
  type: typeof DELETE_BUS_FAILED;
  requestStateDeleteBus: RequestState;
  error: string;
};

export type DeleteBusResetAction = {
  type: typeof DELETE_BUS_RESET;
};

export type BusActions =
  | DeleteBusRequestAction
  | DeleteBusSuccessAction
  | DeleteBusFailedAction
  | DeleteBusResetAction;

// Common Actions

export const FETCH_MPS_TENANTS_REQUESTED = 'FETCH_MPS_TENANTS_REQUESTED';
export const FETCH_MPS_TENANTS_SUCCEEDED = 'FETCH_MPS_TENANTS_SUCCEEDED';
export const FETCH_MPS_TENANTS_FAILED = 'FETCH_MPS_TENANTS_FAILED';

export type FetchMpsTenantsRequestAction = {
  type: typeof FETCH_MPS_TENANTS_REQUESTED;
  requestStateMpsTenants: RequestState;
};

export type FetchMpsTenantsSuccessAction = {
  type: typeof FETCH_MPS_TENANTS_SUCCEEDED;
  requestStateMpsTenants: RequestState;
  mpsTenants: string[];
};

export type FetchMpsTenantsFailedAction = {
  type: typeof FETCH_MPS_TENANTS_FAILED;
  requestStateMpsTenants: RequestState;
};

export type CommonActions =
  | FetchMpsTenantsRequestAction
  | FetchMpsTenantsSuccessAction
  | FetchMpsTenantsFailedAction;
