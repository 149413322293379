import {
  AppBar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from '../../hooks';

import AccountBox from '@mui/icons-material/Person2Rounded';
import Logout from '../auth/Logout';
import Mail from '@mui/icons-material/ChatOutlined';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import { RootState } from '../../redux/store';
import { loadBusses } from '../../redux/actions/AlignmentActions';
import { useNavigate } from 'react-router-dom';

const HeaderTop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userinfo } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (userinfo) {
      if (userinfo.groups?.length) {
        const selectedTenant =
          localStorage.getItem('selectedTenant') ||
          userinfo.groups[0].tenantName;
        if (selectedTenant) {
          localStorage.setItem('selectedTenant', selectedTenant);
          setSelectedTenant(selectedTenant);
        }
      } else {
        localStorage.removeItem('selectedTenant');
        setSelectedTenant(undefined);
      }
    }
  }, [userinfo]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedTenant, setSelectedTenant] = useState<string | undefined>();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTenantChange = (event: SelectChangeEvent) => {
    localStorage.setItem('selectedTenant', event?.target?.value);
    setSelectedTenant(event?.target?.value);
    dispatch(loadBusses(localStorage.getItem('selectedTenant') || ''));
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ backdropFilter: 'blur(20px)' }}
    >
      <Toolbar>
        <Box
          component="img"
          sx={{
            height: 35,
            cursor: 'pointer',
          }}
          alt="Your logo."
          src="./bff-logo.png"
          onClick={() => navigate('/')}
        />
        <Box sx={{ flexGrow: 1 }}></Box>
        <div>
          <FormControl sx={{ marginTop: '5px' }}>
            <InputLabel id="select-tenant">Select Tenant</InputLabel>
            <Select
              labelId="tenant-select-label"
              id="tenant-select"
              value={selectedTenant || 'no-tenant-assigned'}
              label="tenantSelection"
              onChange={handleTenantChange}
              color="primary"
              autoWidth
              style={{ color: '#3f50b5' }}
              sx={{
                minWidth: 120,
                mr: 1,
                height: '2.5rem',
                color: '#3f50b5',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#3f50b5',
                },
                '& .MuiSvgIcon-root': {
                  color: '#3f50b5',
                },
              }}
            >
              {userinfo?.groups?.map((group) => (
                <MenuItem key={group.tenantName} value={group.tenantName}>
                  {group.tenantName}
                </MenuItem>
              ))}
              {!userinfo?.groups?.length || !selectedTenant ? (
                <MenuItem key="no-tenant-assigned" value="no-tenant-assigned">
                  no-tenant-assigned
                </MenuItem>
              ) : undefined}
            </Select>
          </FormControl>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{
              color: '#3f51b5',
            }}
          >
            <AccountBox />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Button
                href="https://chat.platform.vwfs.io/channel/business-services-support"
                color="inherit"
                startIcon={<Mail></Mail>}
              >
                Contact Us
              </Button>
            </MenuItem>

            {userinfo?.groups.filter(
              (group) =>
                group.tenantName === 'vwfs-du-bff' && group.role !== 'readonly'
            ).length ? (
              <MenuItem>
                <Button
                  href="/bus-admin"
                  color="inherit"
                  startIcon={<AdminPanelSettings />}
                >
                  Manage Buses
                </Button>
              </MenuItem>
            ) : null}

            {userinfo?.groups.filter(
              (group) =>
                group.tenantName === selectedTenant && group.role !== 'readonly'
            ).length ? (
              <MenuItem>
                <Button
                  href="/rule-admin"
                  color="inherit"
                  startIcon={<AdminPanelSettings />}
                >
                  Manage Rules
                </Button>
              </MenuItem>
            ) : null}

            <MenuItem onClick={handleClose}>
              <Logout></Logout>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderTop;
