import { combineReducers } from 'redux';
import alignment from './alignment';
import auth from './auth';
import notification from './notification';
import common from './common';
import bus from './bus';

export default combineReducers({
  auth,
  alignment,
  bus,
  notification,
  common,
});
