import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  array as zArray,
  enum as zEnum,
  object as zObject,
  string as zString,
  TypeOf as zTypeOf,
} from 'zod';

import { Info } from '@mui/icons-material';
import CustomTagsComponent from '../../tag/CustomTagsComponent';
import { EventBusBuilderStepProps } from '../bus-builder';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getName, getCodes } = require('country-list');

// Create a schema and the type for our form
const formConfigSchema = zObject({
  events_visibility: zEnum(['private', 'public']),
  events_used_for_testing: zEnum(['true', 'false']),
  events_market: zString().nonempty(),
  'mps:data-privacy:personal-data': zEnum(['true', 'false']),
  'mps:data-privacy:credit-card-data': zEnum(['true', 'false']),
  'mps:data-privacy:classification': zEnum([
    'vwfs:data-classification:public',
    'vwfs:data-classification:internal',
    'vwfs:data-classification:confidential',
  ]),

  events_applications_producer: zArray(
    zString().regex(
      /^([a-zA-Z0-9_-]+)$/,
      'Only latters, numbers, and underscore are allowed'
    )
  ).min(1, 'You have to provide at least one producer'),

  events_application_consumer: zArray(
    zString().regex(
      /^([a-zA-Z0-9_-]+)$/,
      'Only latters, numbers, and underscore are allowed'
    )
  ).min(1, 'You have to provide at least one consumer'),
  // This one is special. We need this to encapsulate all custom tags.
  custom_tags: zArray(
    zString()
      .nonempty()
      .regex(/^([a-zA-Z0-9_./=\-@]+:[a-zA-Z0-9_./=\-@]+)$/)
  )
    .refine(
      (items) => {
        const uniqueKeys = items.map((item) => item.split(':')[0]);
        return new Set(uniqueKeys).size === items.length;
      },
      {
        message: 'Tag keys must be unique!',
      }
    )
    .refine(
      (items) => {
        const result = items
          .map((item) => item.split(':').every((section) => section))
          .every((section) => section);
        return result;
      },
      {
        message: 'Both keys and values are required!',
      }
    ),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const TagWrapper = ({
  tagKey,
  tagDescription,
  children,
}: {
  tagKey: string;
  tagDescription: string;
  children: React.ReactNode[] | React.ReactNode;
}) => {
  return (
    <>
      <Grid container spacing={2}>
        {/* TAG KEY */}
        <Grid item xs={5} marginTop={1}>
          <TextField
            variant="standard"
            disabled
            type="text"
            value={tagKey}
            fullWidth
          />
        </Grid>
        {/* TAG VALUE */}
        <Grid item xs={6} marginTop={1}>
          <Box>{children}</Box>
        </Grid>
        {/* INFO / REMOVE BUTTON */}
        <Grid item xs={1} marginTop={1}>
          <Tooltip title={tagDescription}>
            <IconButton aria-label="info">
              <Info />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

const BusBuilderTags = ({
  stepperPayload,
  setStepperPayload,
  handleBack,
  handleNext,
}: EventBusBuilderStepProps) => {
  // Get all form handlers via useForm
  const {
    trigger,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<FormConfig>({
    defaultValues: {
      events_market:
        stepperPayload.eventBusObject.tags?.events_market || 'GLOBAL',
      events_visibility: stepperPayload.scope as 'private' | 'public',
      events_used_for_testing:
        (stepperPayload.eventBusObject.tags?.events_used_for_testing as
          | 'true'
          | 'false') ?? 'false',
      'mps:data-privacy:classification':
        (stepperPayload.eventBusObject.tags?.[
          'mps:data-privacy:classification'
        ] as
          | 'vwfs:data-classification:public'
          | 'vwfs:data-classification:internal'
          | 'vwfs:data-classification:confidential') ??
        'vwfs:data-classification:confidential',
      'mps:data-privacy:personal-data':
        (stepperPayload.eventBusObject.tags?.[
          'mps:data-privacy:personal-data'
        ] as 'true' | 'false') ?? 'false',
      'mps:data-privacy:credit-card-data':
        (stepperPayload.eventBusObject.tags?.[
          'mps:data-privacy:credit-card-data'
        ] as 'true' | 'false') ?? 'false',
      events_applications_producer:
        stepperPayload.eventBusObject.tags?.events_applications_producer ?? [],
      events_application_consumer:
        stepperPayload.eventBusObject.tags?.events_application_consumer ?? [],
      custom_tags:
        (stepperPayload.eventBusObject.tags?.custom_tags as string[]) ?? [],
    },
    resolver: zodResolver(formConfigSchema),
  });

  // This function is called only when the validation is passed
  const onNext: SubmitHandler<FormConfig> = (data) => {
    stepperPayload.eventBusObject.tags = data;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    stepperPayload.eventBusObject.tags = getValues();
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Box>
          <TagWrapper
            tagKey="events_visibility"
            tagDescription="Defines if the event BUS is public or privite"
          >
            <TextField
              fullWidth
              disabled
              variant="standard"
              {...register('events_visibility')}
            />
          </TagWrapper>

          <TagWrapper
            tagKey="events_used_for_testing"
            tagDescription="Defines if the resource belongs to a demo or test use case."
          >
            <Select
              fullWidth
              variant="standard"
              defaultValue={defaultValues?.events_used_for_testing}
              {...register('events_used_for_testing')}
            >
              <MenuItem value="true">true</MenuItem>
              <MenuItem value="false">false</MenuItem>
            </Select>
          </TagWrapper>

          <TagWrapper
            tagKey="events_market"
            tagDescription="Defining if the event bus is used gloablly or by a specific market."
          >
            <Controller
              rules={{ required: true }}
              control={control}
              name="events_market"
              render={() => (
                <>
                  <Autocomplete
                    defaultValue={defaultValues?.events_market}
                    options={['GLOBAL', ...getCodes()]}
                    // defaultValue={}
                    onChange={(event, values) => {
                      setValue('events_market', values as string);
                      trigger('events_market');
                    }}
                    getOptionLabel={(option) => getName(option) || 'Global'}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Confirm values by pressing Enter"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.events_market?.message}
                  </FormHelperText>
                </>
              )}
            />
          </TagWrapper>

          <TagWrapper
            tagKey="data-privacy:classification"
            tagDescription="The data classification of your application."
          >
            <Select
              fullWidth
              variant="standard"
              defaultValue={defaultValues?.['mps:data-privacy:classification']}
              {...register('mps:data-privacy:classification')}
            >
              <MenuItem value="vwfs:data-classification:public">
                vwfs:data-classification:public
              </MenuItem>
              <MenuItem value="vwfs:data-classification:internal">
                vwfs:data-classification:internal
              </MenuItem>
              <MenuItem value="vwfs:data-classification:confidential">
                vwfs:data-classification:confidential
              </MenuItem>
            </Select>
          </TagWrapper>

          <TagWrapper
            tagKey="data-privacy:personal-data"
            tagDescription="If your application is using PII data."
          >
            <Controller
              rules={{ required: true }}
              control={control}
              name="mps:data-privacy:personal-data"
              render={({ field }) => (
                <RadioGroup
                  row
                  defaultValue={
                    defaultValues?.['mps:data-privacy:personal-data']
                  }
                  {...field}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="true"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="false"
                  />
                </RadioGroup>
              )}
            />
          </TagWrapper>

          <TagWrapper
            tagKey="data-privacy:credit-card-data"
            tagDescription="If your application is using credit card data."
          >
            <Controller
              rules={{ required: true }}
              control={control}
              name="mps:data-privacy:credit-card-data"
              render={({ field }) => (
                <RadioGroup
                  row
                  defaultValue={
                    defaultValues?.['mps:data-privacy:credit-card-data']
                  }
                  {...field}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="true"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="false"
                  />
                </RadioGroup>
              )}
            />
          </TagWrapper>

          <TagWrapper
            tagKey="applications_producer"
            tagDescription="Applications producing events for this bus. Define multiple values by confirming them with Enter key."
          >
            <Controller
              rules={{ required: true }}
              control={control}
              name="events_applications_producer"
              render={() => (
                <>
                  <Autocomplete
                    freeSolo
                    multiple
                    autoSelect
                    defaultValue={defaultValues?.events_applications_producer}
                    options={[]}
                    onChange={(event, values) => {
                      setValue(
                        'events_applications_producer',
                        values.map((value) => value?.trim()) as string[]
                      );
                      trigger('events_applications_producer');
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Confirm values by pressing Enter"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.events_applications_producer?.message}
                    {(errors.events_applications_producer?.length
                      ? errors.events_applications_producer
                      : []
                    ).map!((error) => (
                      <>
                        {error?.message}
                        <br />
                      </>
                    ))}
                  </FormHelperText>
                </>
              )}
            />
          </TagWrapper>

          <TagWrapper
            tagKey="events_application_consumer"
            tagDescription="Applications consuming events from this bus. Define multiple values by confirming them with Enter key."
          >
            <Controller
              rules={{ required: true }}
              control={control}
              name="events_application_consumer"
              render={() => (
                <>
                  <Autocomplete
                    freeSolo
                    multiple
                    autoSelect
                    defaultValue={defaultValues?.events_application_consumer}
                    options={[]}
                    onChange={(event, values) => {
                      setValue(
                        'events_application_consumer',
                        values.map((value) => value?.trim()) as string[]
                      );
                      trigger('events_application_consumer');
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Confirm values by pressing Enter"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.events_application_consumer?.message}
                    {(errors.events_application_consumer?.length
                      ? errors.events_application_consumer
                      : []
                    ).map!((error) => (
                      <>
                        {error?.message}
                        <br />
                      </>
                    ))}
                  </FormHelperText>
                </>
              )}
            />
          </TagWrapper>

          <Typography variant="h6" pt={2} pb={2}>
            Custom Tags
          </Typography>

          <Controller
            rules={{ required: true }}
            control={control}
            name="custom_tags"
            render={() => (
              <>
                <CustomTagsComponent
                  tags={(defaultValues?.custom_tags || []) as string[]}
                  onValueChange={(tags: string[]) => {
                    setValue('custom_tags', tags);
                    // trigger('custom_tags');
                  }}
                >
                  <FormHelperText error>
                    {errors.custom_tags?.message}
                    {(() => {
                      return true;
                    })() && ''}
                    {errors.custom_tags?.length &&
                      'Both tag keys and tag values are required. Both should follow AWS tags naming convention'}
                  </FormHelperText>
                </CustomTagsComponent>
              </>
            )}
          />

          {/* TAGS_BODY END */}
        </Box>
        <Box className="control-box">
          <Button onClick={onBack} color="inherit">
            Back
          </Button>
          <Box className="control-box-separator" />
          <Button type="submit">Next</Button>
        </Box>
      </Box>
    </>
  );
};

export default BusBuilderTags;
