export function registerError(errors: any, pathToError: string) {
  let result = errors;
  const pathSteps = pathToError.split('.');
  for (const pathStep of pathSteps) {
    if (result) result = result[pathStep];
    else break;
  }
  return {
    error: !!result,
    helperText: result ? result.message : '',
  };
}
