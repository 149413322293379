import MuiAlert from '@mui/material/Alert';
import React from 'react';
import { Snackbar } from '@mui/material';

// eslint-disable-next-line react/prop-types
interface CreationAlarmProps {
  open: boolean;
  onClose: () => void;
  success: boolean;
  message: string;
}

const CreationAlert: React.FC<CreationAlarmProps> = ({
  open,
  onClose,
  success,
  message,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={onClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={success ? 'success' : 'error'}
        onClose={onClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default CreationAlert;
