/** @format */

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RequestState } from '../model/requestState';
import { fetchUserInfo } from '../redux/actions/authActions';
import AuthenticatedApp from './AuthenticatedApp';
import StickyFooter from './general/StickyFooter';
import UnauthenticatedApp from './UnauthenticatedApp';

const App: React.FC = () => {
  const { isAuthenticated, requestState } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (requestState === RequestState.INITIAL) {
      dispatch(fetchUserInfo());
    }
  }, [dispatch, requestState]);

  return (
    <div>
      {isAuthenticated === true ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <StickyFooter />
    </div>
  );
};

export default App;
