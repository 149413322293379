import {
  BusActions,
  DELETE_BUS_FAILED,
  DELETE_BUS_REQUEST,
  DELETE_BUS_SUCCESS,
  DELETE_BUS_RESET,
} from '../actions/actionTypes';

import { RequestState } from '../../model/requestState';

export type BusState = {
  deletionError: string | undefined;
  requestStateDeleteBus: RequestState;
};

const initState: BusState = {
  deletionError: undefined,
  requestStateDeleteBus: RequestState.INITIAL,
};

export default (state: BusState = initState, action: BusActions) => {
  switch (action.type) {
    case DELETE_BUS_REQUEST: {
      return {
        ...state,
        deletionError: undefined,
        requestStateDeleteBus: action.requestStateDeleteBus,
      };
    }
    case DELETE_BUS_SUCCESS: {
      return {
        ...state,
        deletionError: undefined,
        requestStateDeleteBus: action.requestStateDeleteBus,
      };
    }
    case DELETE_BUS_FAILED: {
      return {
        ...state,
        deletionError: action.error,
        requestStateDeleteBus: action.requestStateDeleteBus,
      };
    }
    case DELETE_BUS_RESET: {
      return initState;
    }
    default:
      return state;
  }
};
