/** @format */

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import blueGrey from '@mui/material/colors/blueGrey';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vwfs.io">
        VWFS Digital Unit - Business Functions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${blueGrey[100]}`,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(2, 4),
}));

const StickyFooter = () => {
  return (
    <div>
      <CssBaseline />
      <Footer>
        <Container maxWidth="sm">
          <Typography variant="subtitle1" align="center" color="textSecondary">
            Developed in the VWFS Digital Unit Berlin.
          </Typography>
          <Copyright />
        </Container>
      </Footer>
    </div>
  );
};

export default StickyFooter;
