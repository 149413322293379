/** @format */

import { Bus, Tag } from '../model/Bus';

import axios from 'axios';
import { getStageConfig } from '../config/portal';
import { Rule } from '../model/Rule';
import { FailedEvent } from '../model/failedEvent';

export interface EventBusPatchRequest {
  eventBusObject: { tags: { [key: string]: string }[] };
  scope: string;
  accounts: string[];
  metaData?: any;
}

export async function fetchUserData() {
  const result = await axios.get(
    `${getStageConfig().portalBackendUrl}/auth/user-info`,
    { withCredentials: true }
  );
  return result.data;
}

export async function getAllBuses(ownerAccountName: string) {
  const result = await axios.get<Bus[]>(
    `${
      getStageConfig().portalBackendUrl
    }/buses?ownerAccountName=${ownerAccountName}`,
    {
      withCredentials: true,
    }
  );
  return result.data;
}

export async function patchBusByName(
  busName: string,
  req: EventBusPatchRequest,
  ownerAccountName: string
) {
  const result = await axios.patch(
    `${
      getStageConfig().portalBackendUrl
    }/buses/${busName}?ownerAccountName=${ownerAccountName}`,
    req,
    {
      withCredentials: true,
    }
  );
  return result.data;
}

export async function deleteBusByName(name: string, ownerAccountName: string) {
  await axios.delete(
    `${
      getStageConfig().portalBackendUrl
    }/buses/${name}?ownerAccountName=${ownerAccountName}`,
    { withCredentials: true }
  );
}

export async function getBusByName(name: string, ownerAccountName: string) {
  const result = await axios.get(
    `${
      getStageConfig().portalBackendUrl
    }/buses/${name}?ownerAccountName=${ownerAccountName}`,
    { withCredentials: true }
  );
  return result.data;
}

export async function getRulesByBus(bus: string, ownerAccountName: string) {
  const result = await axios.get<Rule[]>(
    `${
      getStageConfig().portalBackendUrl
    }/rules?eventBusName=${bus}&ownerAccountName=${ownerAccountName}`,
    { withCredentials: true }
  );
  return result.data;
}

export async function getFailedEventsByRuleAndBus(
  busName: string,
  ruleName: string
): Promise<FailedEvent[]> {
  const result = await axios.get(
    `${
      getStageConfig().portalBackendUrl
    }/rules/${ruleName}/failed-events?eventBusName=${busName}`,
    { withCredentials: true }
  );
  return result.data.failedEvents;
}

export async function getSchemasByBus(
  ownerAccountName: string,
  eventBusName: string
) {
  try {
    const result = await axios.get(
      `${
        getStageConfig().portalBackendUrl
      }/schemas?ownerAccountName=${ownerAccountName}&eventBusName=${eventBusName}`,
      { withCredentials: true }
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function getSchemaDetails(
  schemaName: string,
  eventBusName: string,
  version: string
) {
  try {
    const result = await axios.get(
      `${
        getStageConfig().portalBackendUrl
      }/schemas/${schemaName}?eventBusName=${eventBusName}&version=${version}`,
      { withCredentials: true }
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export async function getCodeBindings(
  schemaName: string,
  eventBusName: string,
  language: string,
  version: string
) {
  try {
    const result = await axios.get(
      `${
        getStageConfig().portalBackendUrl
      }/schemas/${schemaName}/bindings?eventBusName=${eventBusName}&version=${version}&language=${language}`,
      { withCredentials: true }
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export async function createSchema(
  ownerAccountName: string,
  eventBusName: string,
  schema: string,
  type: string,
  schemaName: string,
  tags?: Tag[]
) {
  const body = {
    schemaName,
    template: schema,
    type,
    tags,
  };
  const queryParams = {
    ownerAccountName,
    eventBusName,
  };
  return await axios.post(
    `${getStageConfig().portalBackendUrl}/schemas`,
    body,
    { params: queryParams, withCredentials: true }
  );
}

export async function getMpsTenants() {
  return await axios
    .get(`${getStageConfig().portalBackendUrl}/mps-tenants`, {
      withCredentials: true,
    })
    .then(({ data }) => data);
}

export async function getQuotas(): Promise<{
  registries: number;
}> {
  return await axios
    .get(`${getStageConfig().portalBackendUrl}/quotas`, {
      withCredentials: true,
    })
    .then(({ data }) => data);
}
