import { fetchUserData } from '../../api/client';
import { RequestState } from '../../model/requestState';
import { RootState, ThunkAction } from '../store';
import {
  AuthActions,
  FETCH_USER_INFO_FAILED,
  FETCH_USER_INFO_SUCCEEDED,
} from './actionTypes';

export const fetchUserInfo =
  (): ThunkAction<void, RootState, unknown, AuthActions> =>
  async (dispatch) => {
    try {
      const response = await fetchUserData();
      dispatch({
        type: FETCH_USER_INFO_SUCCEEDED,
        userinfo: response,
        requestState: RequestState.SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: FETCH_USER_INFO_FAILED,
        requestState: RequestState.ERROR,
      });
    }
  };
