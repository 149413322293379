import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FileCopy, GetApp } from '@mui/icons-material';
import React, { useState } from 'react';
import { getCodeBindings, getSchemaDetails } from '../../../api/client';

import EditorPane from '../../editor/Editor';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from '../../../hooks';

type Schema = {
  name: string;
  versionCount: string;
  jsonString?: string;
};
interface SchemaListProps {
  schemas: Schema[];
}

type Map = {
  [key: string]: string;
};

const languageMap: Map = {
  Typescript: 'TypeScript3',
  Python: 'Python36',
  Go: 'Go1',
  Java: 'Java8',
};

const SchemaTable: React.FC<SchemaListProps> = ({ schemas }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [language, setLanguage] = useState('Typescript');
  const registry = useAppSelector((state) => state.alignment.bus?.name || '');

  const handleRowExpand = async (index: number) => {
    const schema: Schema = schemas[index];
    const details = await getSchemaDetails(
      schema.name,
      registry,
      schema.versionCount
    );
    schema.jsonString = details.content;
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCopyClick = () => {
    const index = expandedRowIndex ?? 0;
    const content: string = schemas[index].jsonString ?? '';
    if (content && content.length > 0) {
      navigator.clipboard.writeText(content);
    }
  };

  const handleDownloadClick = async () => {
    const index = expandedRowIndex ?? 0;
    const schema: Schema = schemas[index];
    const bindings = await getCodeBindings(
      schema.name,
      registry,
      languageMap[language],
      schema.versionCount
    );
    const base64String = bindings.base64;
    const blob = b64toBlob(base64String, 'application/zip');
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `code-bindings-${language}.zip`;
    link.click();

    URL.revokeObjectURL(url);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
  };

  const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return schemas.length > 0 ? (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Schema Name</strong>
            </TableCell>
            <TableCell>
              <strong>Schema Version</strong>
            </TableCell>
            <TableCell>
              <strong>Details</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schemas.map((schema, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>{schema.name}</TableCell>
                <TableCell>{schema.versionCount}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRowExpand(index)}>
                    {expandedRowIndex === index ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>
                  <Collapse
                    in={expandedRowIndex === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box>
                      <Box sx={{ display: 'flex', gap: 2, mb: '5px' }}>
                        <Button
                          startIcon={<FileCopy />}
                          onClick={handleCopyClick}
                        >
                          Copy Schema
                        </Button>
                        <Button
                          startIcon={<GetApp />}
                          onClick={handleDownloadClick}
                        >
                          Download Code Bindings
                        </Button>
                        <Select
                          value={language}
                          onChange={handleLanguageChange}
                          defaultValue={language}
                          sx={{
                            mr: 1,
                            height: '2.5rem',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#1769aa',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#1769aa',
                            },
                          }}
                        >
                          <MenuItem value="Typescript" color="blue">
                            Typescript
                          </MenuItem>
                          <MenuItem value="Java">Java</MenuItem>
                          <MenuItem value="Go">Go</MenuItem>
                          <MenuItem value="Python">Python</MenuItem>
                        </Select>
                      </Box>
                      <EditorPane
                        height={'400px'}
                        width={'100%'}
                        value={schema.jsonString}
                      />
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: '100px',
        }}
      >
        <CardContent>
          <Typography variant="h5" align="center" sx={{ my: 2 }}>
            There are no schemas for your Bus yet.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SchemaTable;
