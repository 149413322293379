import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  array as zArray,
  enum as zEnum,
  object as zObject,
  string as zString,
  TypeOf as zTypeOf,
} from 'zod';

import { EventBusBuilderStepProps } from '../bus-builder';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadMpsTenants } from '../../../redux/actions/CommonActions';

const MAX_ACCESS_TENANTS_LENGTH = 256;

// Create a schema and the type for our form
const formConfigSchema = zObject({
  scope: zEnum(['private', 'public']),
  accounts: zArray(zString())
    .optional()
    .refine(
      (val) => {
        const concatenatedValue = val ? val.join(':') : '';
        return concatenatedValue.length < MAX_ACCESS_TENANTS_LENGTH;
      },
      {
        message: 'Too many accounts selected. Please remove an account.',
      }
    ),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const BusBuilderAccess = ({
  stepperPayload,
  setStepperPayload,
  handleBack,
  handleNext,
  firstStep,
}: EventBusBuilderStepProps) => {
  const dispatch = useAppDispatch();

  // Get all form handlers via useForm
  const {
    control,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<FormConfig>({
    defaultValues: {
      accounts: stepperPayload.accounts,
      scope: (stepperPayload.scope as 'private' | 'public') ?? 'private',
    },
    resolver: zodResolver(formConfigSchema),
  });

  const [scope, setScope] = useState(stepperPayload.scope ?? 'private');
  const mpsTenants = useAppSelector<string[]>(
    (state) => state.common.mpsTenants
  );

  useEffect(() => {
    if (!mpsTenants.length) {
      dispatch(loadMpsTenants());
    }
  }, [dispatch, mpsTenants]);

  const onNext: SubmitHandler<FormConfig> = ({ accounts, scope }) => {
    stepperPayload.scope = scope;
    stepperPayload.accounts = accounts;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    stepperPayload.scope = getValues().scope;
    stepperPayload.accounts = getValues().accounts;
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Box>
          <Typography variant="h6">Scope</Typography>
          <Controller
            rules={{ required: true }}
            control={control}
            name="accounts"
            render={() => (
              <>
                <Select
                  fullWidth
                  variant="outlined"
                  defaultValue={defaultValues?.scope}
                  onChange={(event) => {
                    setValue(
                      'scope',
                      event.target.value as 'private' | 'public'
                    );
                    setScope(event.target.value);
                  }}
                >
                  <MenuItem value="public">public</MenuItem>
                  <MenuItem value="private">private</MenuItem>
                </Select>
              </>
            )}
          />

          {scope === 'private' && (
            <>
              <Typography variant="h6">Accounts</Typography>
              <Controller
                control={control}
                name="accounts"
                render={() => (
                  <>
                    <Autocomplete
                      multiple
                      defaultValue={defaultValues?.accounts}
                      options={mpsTenants}
                      onChange={(event, values) => {
                        setValue('accounts', values as string[]);
                        trigger('accounts');
                      }}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Confirm values by pressing Enter"
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.accounts?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </>
          )}
        </Box>
        <Box className="control-box">
          {!firstStep ? (
            <Button onClick={onBack} color="inherit">
              Back
            </Button>
          ) : null}
          <Box className="control-box-separator" />
          <Button type="submit">Next</Button>
        </Box>
      </Box>
    </>
  );
};

export default BusBuilderAccess;
