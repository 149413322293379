/** @format */

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  Edge,
  EdgeChange,
  Node,
  Position,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import HubIcon from '@mui/icons-material/HubOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import dagre from 'dagre';
import { listEventRuleApplications } from '../../api/rule-application';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Bus } from '../../model/Bus';
import { Rule } from '../../model/Rule';
import { RequestState } from '../../model/requestState';
import { loadBusRules } from '../../redux/actions/AlignmentActions';
import BusBuilder from '../bus/bus-builder';
import BusNode from './BusNode';
import RuleNode from './RuleNode';
import SourceNode from './SourceNode';

const nodeTypes = {
  busNode: BusNode,
  sourceNode: SourceNode,
  ruleNode: RuleNode,
};

const flowStyles = {
  backgroundColor: '#fafafa',
  height: '100vh',
  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
};

const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '50px',
};

const noBussesText = {
  textAlign: 'center',
  marginBottom: '25px',
};

const createButton = {
  alignSelf: 'center',
};

const circularSpinner = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-25%, -50%)',
};

const styleBus = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const styleIcon = {
  ml: '8px',
};

function buildSourceNodes(rules: Rule[], outArr: Node[]) {
  return rules.map((rule) =>
    outArr.push({
      id: `source:${rule.name}`,
      sourcePosition: Position.Right,
      type: 'sourceNode',
      data: { label: `Sources: ${rule.name}`, eventPattern: rule.eventPattern },
      position: { x: 0, y: 0 },
    })
  );
}

function buildBusNodes(busses: Bus[], outArr: Node[]) {
  return busses.map((bus) =>
    outArr.push({
      id: `bus:${bus.name}`,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      type: 'busNode',
      data: { label: bus.name },
      position: { x: 0, y: 0 },
    })
  );
}

function buildRuleNodes(rules: Rule[], outArr: Node[]) {
  return rules.map((rule) =>
    outArr.push({
      id: `rule:${rule.name}`,
      type: 'ruleNode',
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      data: { label: rule.name, retrySchedule: rule.retrySchedule },
      position: { x: 0, y: 0 },
    })
  );
}

const iconProvider = (type: string) => {
  if (type === 'HTTP') {
    return <LanguageOutlinedIcon sx={styleIcon}></LanguageOutlinedIcon>;
  } else if (type === 'EVENT_BUS') {
    return <HubIcon sx={styleIcon}></HubIcon>;
  }
};

function buildTargetNodes(rules: Rule[], outArr: Node[]) {
  rules.map((rule) =>
    rule.targets.map((target) =>
      outArr.push({
        id: `target:${target.id}`,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: {
          width: 200,
        },
        data: {
          label: (
            <Grid container justifyContent="center" alignItems="center">
              <p>{target.targetName}</p>
              {iconProvider(target.targetType)}
            </Grid>
          ),
        },
        position: { x: 0, y: 0 },
        type: 'default',
      })
    )
  );
}

function buildSourceBusEdges(rules: Rule[], outArr: Edge[]) {
  return rules.map((rule) =>
    outArr.push({
      id: `source:${rule.name}:${rule.eventBusName}`,
      source: `source:${rule.name}`,
      target: `bus:${rule.eventBusName}`,
      type: 'smoothstep',
      animated: true,
    })
  );
}

function buildBusRuleEdges(rules: Rule[], outArr: Edge[]) {
  return rules.map((rule) =>
    outArr.push({
      id: `bus:${rule.eventBusName}:${rule.name}`,
      source: `bus:${rule.eventBusName}`,
      type: 'smoothstep',
      target: `rule:${rule.name}`,
      animated: true,
    })
  );
}

function buildRuleTargetEdges(rules: Rule[], outArr: Edge[]) {
  rules.map((rule) =>
    rule.targets.map((target) =>
      outArr.push({
        id: `rule:${rule.name}:${target.id}`,
        source: `rule:${rule.name}`,
        target: `target:${target.id}`,
        type: 'smoothstep',
        animated: true,
        label: target.targetType === 'EVENT_BUS' ? 'Event Bus' : 'HTTP',
      })
    )
  );
}

function reflowLayoutedElements(nodes: Node[], edges: Edge[]) {
  const dagreGraph = new dagre.graphlib.Graph({ directed: true });
  const nodeWidth = 180;
  const nodeHeight = 100;
  dagreGraph.setGraph({ rankdir: 'LR' });

  nodes.forEach((node) => {
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: node.type === 'sourceNode' ? nodeHeight + 20 : nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target, { minlen: 3 });
  });

  dagre.layout(dagreGraph);

  // Call this after layout is build
  // This loop moves the whole wlow to the top left corner
  nodes.forEach((node) => {
    const { x, y } = dagreGraph.node(node.id);
    node.position = {
      x: x - nodeWidth / 2 + 20,
      y: y - nodeHeight / 2,
    };
  });

  return { nodes, edges };
}

const PageLayout: React.FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [updater, setUpdater] = useState(0);

  const dispatch = useAppDispatch();

  const bus = useAppSelector<Bus | undefined>((state) => state.alignment.bus);
  const busses = useAppSelector<Bus[]>((state) => state.alignment.busses ?? []);
  const rules = useAppSelector<Rule[]>((state) => state.alignment.rules ?? []);

  useEffect(() => {
    const ruleApplications = JSON.parse(
      localStorage.getItem('rule-applications') || '[]'
    );
    ruleApplications.length &&
      listEventRuleApplications(bus?.name).then((res) => {
        const processedApplications = res.filter((ruleApplication: any) => {
          return (
            ruleApplications.includes(ruleApplication.id) &&
            ruleApplication.approvalStatus === 'approved' &&
            !(
              ruleApplication.status === 'pending' ||
              ruleApplication.status === 'processing'
            )
          );
        });
        console.log('PROCESSED', ruleApplications);
        if (processedApplications.length && bus) {
          dispatch(
            loadBusRules(bus.name, processedApplications[0].ownerAccountName)
          );
          localStorage.setItem('rule-applications', '[]');
        }
      });
    setTimeout(() => setUpdater(updater + 1), 5000);
  }, [updater]);

  function findPath(edgeName: string): string[] {
    const path: string[] = [edgeName];
    const parts: string[] = edgeName.split(':');
    switch (parts[0]) {
      case 'source':
        path.push(`bus:${parts[2]}:${parts[1]}`);
        for (const edge of edges) {
          if (edge.id.startsWith(`rule:${parts[1]}:`)) {
            path.push(edge.id);
          }
        }
        break;
      case 'bus':
        path.push(`source:${parts[2]}:${parts[1]}`);
        for (const edge of edges) {
          if (edge.id.startsWith(`rule:${parts[2]}:`)) {
            path.push(edge.id);
          }
        }
        break;
      case 'rule':
        const sourceEdge = edges.find((edge) =>
          edge.id.startsWith(`source:${parts[1]}:`)
        );
        if (sourceEdge) {
          path.push(sourceEdge.id);
          path.push(`bus:${sourceEdge.id.split(':')[2]}:${parts[1]}`);
        }
        break;
      default:
        console.error('unknown edge type');
        break;
    }
    return path;
  }

  function updateHighlighting(changes: EdgeChange[]) {
    const updatedEdges = [];
    let edgesToSelect: string[] = [];
    if (
      changes.length === 1 &&
      changes[0].type === 'select' &&
      changes[0].selected
    ) {
      edgesToSelect = findPath(changes[0].id);
      for (const edge of edges) {
        if (edgesToSelect.includes(edge.id)) edge.selected = true;
        updatedEdges.push(edge);
      }
      setEdges(updatedEdges);
    }
  }

  function handleNodeClick(node: Node) {
    switch (node.id.split(':')[0]) {
      case 'source':
        updateHighlighting([
          {
            type: 'select',
            id: `${node.id}:${bus?.name || ''}`,
            selected: true,
          },
        ]);
        break;
      case 'rule':
        updateHighlighting([
          {
            type: 'select',
            id: `bus:${bus?.name || ''}:${node.id.split(':')[1]}`,
            selected: true,
          },
        ]);
        break;
      case 'target':
        const ruleEdge = edges.find(
          (edge) =>
            edge.id.startsWith('rule:') &&
            edge.id.endsWith(node.id.split(':')[1])
        );
        updateHighlighting([
          {
            type: 'select',
            id: ruleEdge?.id || '',
            selected: true,
          },
        ]);
        break;
      default:
        break;
    }
  }
  /**
   * Transform the service data into the nodes and edges of react flow.
   * Dont do anything else.
   */
  useEffect(() => {
    const _nodes: Node[] = [];
    const _edges: Edge[] = [];
    // Build all the nodes and edges
    buildSourceNodes(rules, _nodes);
    buildBusNodes(
      busses.filter((sdkBus) => !bus || sdkBus.name === bus.name),
      _nodes
    );
    buildRuleNodes(rules, _nodes);
    buildTargetNodes(rules, _nodes);
    buildSourceBusEdges(rules, _edges);
    buildBusRuleEdges(rules, _edges);
    buildRuleTargetEdges(rules, _edges);
    reflowLayoutedElements(_nodes, _edges);
    // Update nodes
    setNodes(_nodes);
    setEdges(_edges);
  }, [rules, setNodes, busses, bus, setEdges]);

  const requestStateBusses = useAppSelector(
    (state) => state.alignment.requestStateBusses
  );
  const requestStateRules = useAppSelector(
    (state) => state.alignment.requestStateRules
  );
  const onConnect = useCallback(
    (params: Connection) => setEdges((els) => addEdge(params, els)),
    [setEdges]
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderPage = () => {
    if (requestStateBusses === RequestState.SUCCESS && !busses.length) {
      // Show button to add new bus when there are none
      return (
        <Box sx={container}>
          <Typography variant="h6" sx={noBussesText}>
            There are no event buses yet.
          </Typography>
          <Button
            variant="contained"
            size="large"
            aria-label="add-bus"
            color="primary"
            sx={createButton}
            onClick={handleOpen}
          >
            Create New Bus
          </Button>
        </Box>
      );
    } else if (
      // Show loading spinner if...
      // ...busses are about to load
      requestStateBusses === RequestState.INITIAL ||
      // ...busses are loading
      requestStateBusses === RequestState.FETCHING ||
      // ...rules are loading
      requestStateRules === RequestState.FETCHING ||
      // ...busses are loaded and rules are about to be loaded,
      // but only if there is actually a bus
      (requestStateBusses === RequestState.SUCCESS &&
        requestStateRules === RequestState.INITIAL &&
        busses.length)
    ) {
      return <CircularProgress sx={circularSpinner} />;
    } else {
      // Show event bus flow
      return (
        <ReactFlow
          nodesConnectable={false}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={(changes) => {
            onEdgesChange(changes);
            updateHighlighting(
              changes.filter((change) => {
                return change.type === 'select' && change.selected === true;
              })
            );
          }}
          onConnect={onConnect}
          onNodeClick={(event, node) => {
            handleNodeClick(node);
          }}
          style={flowStyles}
          onlyRenderVisibleElements
          fitViewOptions={{
            minZoom: 1,
          }}
          attributionPosition="bottom-left"
        >
          <Background
            variant={BackgroundVariant.Dots}
            color="#E4E7E9"
            gap={8}
            size={1}
          />
        </ReactFlow>
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '80vh',
          overflow: 'auto',
          '.react-flow__handle': {
            opacity: 0,
          },
        }}
      >
        {renderPage()}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBus}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add BUS
          </Typography>
          <BusBuilder setModalStatus={setOpen} />
        </Box>
      </Modal>
    </>
  );
};

export default PageLayout;
