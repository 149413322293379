import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { object as zObject, string as zString, TypeOf as zTypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { RuleBuilderStepProps } from '../rule-builder';

// Create a schema and the type for our form
const formConfigSchema = zObject({
  businessJustification: zString().nonempty(
    'You have to provide the business justification'
  ),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const RuleBuilderJustification = ({
  stepperPayload,
  setStepperPayload,
  handleNext,
  handleBack,
}: RuleBuilderStepProps) => {
  // Get all form handlers via useForm
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<FormConfig>({
    defaultValues: {
      businessJustification: stepperPayload.businessJustification,
    },
    resolver: zodResolver(formConfigSchema),
  });

  const onNext: SubmitHandler<FormConfig> = ({ businessJustification }) => {
    stepperPayload.businessJustification = businessJustification;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    stepperPayload.businessJustification = getValues('businessJustification');
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Box>
          <TextField
            fullWidth
            label="Business Justification"
            multiline
            rows={10}
            error={!!errors.businessJustification}
            helperText={errors.businessJustification?.message}
            defaultValue={defaultValues?.businessJustification}
            {...register('businessJustification')}
          />
        </Box>
        <Box className="control-box">
          <Button onClick={onBack} color="inherit">
            Back
          </Button>
          <Box className="control-box-separator" />
          <Button type="submit">Next</Button>
        </Box>
      </Box>
    </>
  );
};

export default RuleBuilderJustification;
