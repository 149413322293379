import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';

import DeleteIcon from '@mui/icons-material/Delete';
import ReportIcon from '@mui/icons-material/Report';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { AxiosError } from 'axios';
import { v4 } from 'uuid';
import { getRulesByBus } from '../../api/client';
import { postEventRuleApplication } from '../../api/rule-application';
import { useAppSelector } from '../../hooks';
import { Rule } from '../../model/Rule';
import RuleUpdater from '../builders/rules/rule-updater';
import { FailedEventsOverview } from './rules/FailedEventsOverview';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'scroll',
};

interface RuleNodeProps {
  data: {
    label: string;
    retrySchedule?: number;
  };
}

export interface EventBusTargetProps {
  targetEventBusName?: string;
  targetEventBusArn: string;
}

export interface HttpTargetProps {
  endpoint: string;
  httpMethod: string;
  authorizationType:
    | 'API_KEY'
    | 'OAUTH_CLIENT_CREDENTIALS'
    | 'OAUTH_USERNAME_PASSWORD_FLOW';
  apiKeyParams?: {
    apiKeyName: string;
    apiKeyValue: string;
  };
  oauthParams?: {
    authorizationEndpoint: string;
    clientId: string;
    clientSecret: string;
    username: string;
    password: string;
  };
}
export interface TargetRequest {
  targetType: 'EVENT_BUS' | 'HTTP';
  targetToDelete?: boolean;
  targetOrigin?: 'existing' | 'new';
  targetName?: string;
  targetDescription?: string;
  httpTargetProps?: HttpTargetProps;
  eventBusTargetProps?: EventBusTargetProps;
}

export type RuleStepperPayload = {
  eventRuleObject: {
    name?: string;
    description?: string;
    eventPattern?: string;
    eventBusName?: string;
    targets: Record<string, TargetRequest>;
    retrySchedule: number;
    [key: string]: any;
  };
  target?: string;
  prefix?: string;
  action?: string;
  ownerAccountName?: string;
  businessJustification?: string;
};

const RuleNode: React.FC<RuleNodeProps> = ({ data }) => {
  const { label, retrySchedule } = data;

  const [stepperPayload, setStepperPayload] =
    React.useState<RuleStepperPayload>({
      eventRuleObject: {
        retrySchedule: 0,
        targets: {},
      },
    });

  const bus = useAppSelector((state) => state.alignment.bus);
  const busses = useAppSelector((state) => state.alignment.busses);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openUpdate, setOpenUpdate] = useState(false);

  const getOwner = (busName: string) => {
    const bus = busses.find((bus) => bus.name === busName);
    return bus?.tags.find((tag) => tag.key === 'events_creator')?.value || '';
  };

  const handleOpenUpdate = () => {
    if (bus) {
      const selectedTenant =
        window.localStorage.getItem('selectedTenant') ?? '';
      getRulesByBus(bus.name, selectedTenant).then(
        (serviceResponse: Rule[]) => {
          const rule = serviceResponse
            .filter(({ name }) => name === label)
            .at(0);

          if (rule) {
            setStepperPayload({
              eventRuleObject: {
                eventBusName: rule.eventBusName,
                eventPattern: (() => {
                  const eventPattern = JSON.parse(rule.eventPattern);
                  if (eventPattern.source) {
                    eventPattern.source = eventPattern.source.filter(
                      (source: any) =>
                        (typeof source === 'string' &&
                          !source.endsWith('retry-hook')) ||
                        typeof source !== 'string'
                    );
                  }
                  if (eventPattern['detail-type']) {
                    eventPattern['detail-type'] = eventPattern[
                      'detail-type'
                    ].filter(
                      (source: any) =>
                        typeof source === 'string' &&
                        !source.endsWith('retry-hook')
                    );
                  }
                  return JSON.stringify(eventPattern);
                })(),
                name: rule.name,
                retrySchedule: rule.retrySchedule as number,
                targets: rule.targets.reduce(
                  (prev, curr) => ({
                    ...prev,
                    [v4()]: {
                      ...curr,
                      targetOrigin: 'existing',
                    },
                  }),
                  {}
                ),
              },
              action: 'update',
              ownerAccountName: selectedTenant,
            });
            setOpenUpdate(true);
          }
        }
      );
    }
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [openFailedEvents, setOpenFailedEvents] = useState(false);

  const handleOpenFailedEvents = () => setOpenFailedEvents(true);
  const handleCloseFailedEvents = () => setOpenFailedEvents(false);

  const [businessJustification, setBusinessJustification] = useState('');
  const [businessJustificationError, setBusinessJustificationError] =
    useState('');

  const [deletionResult, setDeletionResult] = React.useState<
    | {
        status: 'success' | 'failure';
        message: string;
      }
    | undefined
  >(undefined);

  const handleRuleDeletion = () => {
    if (!businessJustification) {
      setBusinessJustificationError('You must provide business justification!');
    }
    if (bus && businessJustification) {
      const selectedTenant =
        window.localStorage.getItem('selectedTenant') ?? '';
      postEventRuleApplication({
        eventRuleObject: {
          eventBusName: bus.name,
          name: label,
          targets: [],
        },
        action: 'delete',
        ownerAccountName: selectedTenant,
        businessJustification,
      })
        .then((res) => {
          localStorage.setItem(
            'rule-applications',
            JSON.stringify([
              ...JSON.parse(localStorage.getItem('rule-applications') || '[]'),
              res.id,
            ])
          );
          setDeletionResult({
            status: 'success',
            message: `Your request has been submitted and is waiting for approval. Please contact an approver of the hosting tenant ${selectedTenant} or the bus owner ${getOwner(
              bus.name || ''
            )}. You will receive all the updates via email.`,
          });
        })
        .catch((err: AxiosError<{ message: string }>) => {
          let detail = '';
          if (err.response && err.response.data) {
            detail = err.response.data.message;
          }
          const message =
            err.response?.status === 401
              ? `${err.message}. It looks like your access token has expired. Your progress is saved (excluding the sensitive data), you can refresh the page and submit it again.`
              : `${err.message}. ${detail}`;
          setDeletionResult({
            status: 'failure',
            message,
          });
        });
    }
  };

  const [updateResult, setUpdateResult] = React.useState<
    | {
        status: 'success' | 'failure';
        message: string;
      }
    | undefined
  >(undefined);

  return (
    <>
      <div
        style={{
          width: '220px',
          background: 'white',
          textAlign: 'center',
          borderRadius: '4px',
          border: 'solid 1px grey',
        }}
      >
        <div
          style={{
            height: '40px',
            background: 'rgb(62, 105, 95)',
            paddingTop: '1px',
            marginBottom: '5%',
            color: 'white',
            textDecoration: 'bolder',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px 3px 0px 0px',
          }}
        >
          <Typography variant="body1" sx={{ verticalAlign: 'middle' }}>
            {label}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            marginBottom: '5%',
          }}
        >
          <Grid
            container
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                onClick={handleOpenUpdate}
                variant="outlined"
                size="small"
              >
                <UpgradeIcon></UpgradeIcon>
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleOpenDelete}
                variant="outlined"
                size="small"
                color="error"
              >
                Delete
                <DeleteIcon></DeleteIcon>
              </Button>
            </Grid>
            {retrySchedule !== undefined && retrySchedule > 0 ? (
              <Grid item>
                <Button
                  onClick={handleOpenFailedEvents}
                  variant="outlined"
                  size="small"
                >
                  Failed Events
                  <ReportIcon></ReportIcon>
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </div>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {deletionResult ? (
          <Box
            sx={{ ...style, width: 768 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={3}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
          >
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {deletionResult.status === 'failure'
                ? 'Request Failed'
                : 'Success'}
            </Typography>
            <Typography variant="body1">{deletionResult.message}</Typography>
            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => {
                setDeletionResult(undefined);
                setOpenDelete(false);
              }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <Box
            sx={{ ...style, width: '768px' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={3}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
          >
            <Typography variant="h5">
              {`Do you really want to delete ${label}? This action cannot be undone.`}
            </Typography>

            <Box
              component="form"
              noValidate
              autoComplete="off"
              style={{ marginTop: '20px', width: '100%' }}
            >
              <Box>
                <TextField
                  fullWidth
                  label="Business Justification"
                  multiline
                  rows={10}
                  required
                  onChange={(e) =>
                    setBusinessJustification(e.target.value || '')
                  }
                  error={!!businessJustificationError}
                  helperText={businessJustificationError}
                />
              </Box>
            </Box>

            <Box mt={2} sx={{ '& > button': { marginRight: '8px' } }}>
              <Button variant="contained" onClick={() => setOpenDelete(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleRuleDeletion}>
                Confirm
              </Button>
            </Box>
          </Box>
        )}
      </Modal>

      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {updateResult ? (
          <Box
            sx={{ ...style, width: 768 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={3}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
          >
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {updateResult.status === 'failure' ? 'Request Failed' : 'Success'}
            </Typography>
            <Typography variant="body1">{updateResult.message}</Typography>
            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => {
                setUpdateResult(undefined);
                setOpenUpdate(false);
              }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <Box sx={style}>
            <RuleUpdater setModalStatus={setOpenUpdate} rule={stepperPayload} />
          </Box>
        )}
      </Modal>

      <Modal
        open={openFailedEvents}
        onClose={handleCloseFailedEvents}
        aria-labelledby="modal-modal-info"
        aria-describedby="modal-modal-info-description"
      >
        <Box sx={{ ...style, width: '140vh', maxHeight: '90vh' }}>
          <FailedEventsOverview
            eventBusName={bus!.name}
            ruleName={label}
            handleCloseFailedEvents={handleCloseFailedEvents}
          ></FailedEventsOverview>
        </Box>
      </Modal>

      <Handle type="source" position={Position.Right} id="a" />
      <Handle type="target" position={Position.Left} id="b" />
    </>
  );
};

export default RuleNode;
