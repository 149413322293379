import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object as zObject, string as zString, TypeOf as zTypeOf } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { RuleBuilderStepProps } from '../rule-builder';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { loadMpsTenants } from '../../../../redux/actions/CommonActions';

enum SourceType {
  AWS = 'aws',
  SALESFORCE = 'salesforce',
  SALESFORCE_APPFLOW = 'salesforceAppflow',
}

const srcMap: Map<SourceType, string> = new Map([
  [SourceType.SALESFORCE, 'aws.partner/salesforce.com'],
  [SourceType.SALESFORCE_APPFLOW, 'aws.partner/appflow/salesforce.com'],
  [SourceType.AWS, '{MPS-ACCOUNT-ALIAS}/{AWS-REGION}/{APPLICATION-NAME}'],
]);

const errorMap: Map<SourceType, string> = new Map([
  [SourceType.SALESFORCE, 'Must start with "aws.partner/salesforce.com".'],
  [
    SourceType.SALESFORCE_APPFLOW,
    'Must start with "aws.partner/appflow/salesforce.com".',
  ],
  [
    SourceType.AWS,
    'Please check the prefix. The value must follow the pattern MPS-ACCOUNT-ALIAS/AWS-REGION/APPLICATION-NAME, e.g., vwfs-du-bff-prod/eu-central-1/my-application',
  ],
]);

const prefixSchema = (
  mpsTenantRefiner: (data: { target: string; prefix: string }) => boolean
) =>
  zObject({
    target: zString(),
    prefix: zString()
      .min(1, 'Must be specified')
      .regex(
        /^[a-zA-Z0-9-]+-(dev|int|cons|prod)\/(af|ap|ca|eu|me|sa|us)-(central|north|(north(?:east|west))|south|south(?:east|west)|east|west)-\d+\/[a-zA-Z0-9_\-.]+$/
      )
      .or(zString().startsWith('aws.partner/salesforce.com'))
      .or(zString().startsWith('aws.partner/appflow/salesforce.com')),
  }).refine(mpsTenantRefiner, { path: ['prefix'] });

type FormConfig = zTypeOf<ReturnType<typeof prefixSchema>>;

const RuleBuilderPatterns: React.FC<RuleBuilderStepProps> = ({
  stepperPayload,
  setStepperPayload,
  handleNext,
  handleBack,
}: RuleBuilderStepProps) => {
  const dispatch = useAppDispatch();

  // Validator the MPS tenants. This is done dynamically, because the tenants are also fetched async.
  const mpsTenants = useAppSelector<string[]>(
    (state) => state.common.mpsTenants
  );
  const mpsTenantRefiner = (
    data: zTypeOf<ReturnType<typeof prefixSchema>>
  ): boolean => {
    if (
      stepperPayload.target === SourceType.AWS &&
      data.prefix &&
      !data.prefix.startsWith('aws.partner/salesforce.com') &&
      !data.prefix.startsWith('aws.partner/appflow/salesforce.com')
    ) {
      const alias = data.prefix.split('/')[0];
      const tenant = alias ? alias.substring(0, alias.lastIndexOf('-')) : '';
      return mpsTenants.includes(tenant);
    }
    return true;
  };

  // Get all form handlers via useForm
  const {
    setValue,
    getValues,
    handleSubmit,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormConfig>({
    resolver: zodResolver(prefixSchema(mpsTenantRefiner)),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (!mpsTenants.length) {
      dispatch(loadMpsTenants());
    }
  }, [dispatch, mpsTenants]);

  useEffect(() => {
    // Pre-fill fields
    if (!stepperPayload.prefix) {
      const existingPatternPrefix = JSON.parse(
        stepperPayload.eventRuleObject.eventPattern || '{}'
      ).source[0]?.prefix;

      let selectedTarget = SourceType.AWS; // Default
      if (existingPatternPrefix) {
        if (
          existingPatternPrefix.startsWith(
            srcMap.get(SourceType.SALESFORCE_APPFLOW)
          )
        ) {
          selectedTarget = SourceType.SALESFORCE_APPFLOW;
        } else if (
          existingPatternPrefix.startsWith(srcMap.get(SourceType.SALESFORCE))
        ) {
          selectedTarget = SourceType.SALESFORCE;
        } else {
          selectedTarget = SourceType.AWS;
        }
      }

      setStepperPayload({
        ...stepperPayload,
        prefix: existingPatternPrefix,
        target: selectedTarget,
      });
      setValue('target', selectedTarget);
      setValue('prefix', existingPatternPrefix);
    }
  }, [stepperPayload, setStepperPayload, setValue]);

  useEffect(() => {
    if (errors.prefix?.message) {
      const message = errorMap.get(getValues('target') as SourceType);
      setError('prefix', { message });
    }
  }, [errors, getValues, setError]);

  const handleSourceChange = (event: SelectChangeEvent<string>) => {
    const prefix = srcMap.get(event.target.value as SourceType);
    if (prefix) {
      stepperPayload.target = event.target.value;
      stepperPayload.prefix = prefix;
      setValue('prefix', prefix);
      clearErrors('prefix');
    }
  };

  const onNext: SubmitHandler<FormConfig> = ({ prefix }) => {
    const currentPattern = {
      source: [
        {
          prefix,
        },
      ],
    };
    stepperPayload.eventRuleObject.eventPattern = JSON.stringify(
      currentPattern,
      null,
      2
    );
    stepperPayload.prefix = getValues('prefix');
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    stepperPayload.prefix = getValues('prefix');
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Typography variant="h6">Event Pattern</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Select
              title="Select Source"
              required
              fullWidth
              value={stepperPayload.target || SourceType.AWS}
              {...register('target', { required: true })}
              onChange={handleSourceChange}
            >
              <MenuItem selected value={SourceType.AWS}>
                AWS
              </MenuItem>
              <MenuItem selected value={SourceType.SALESFORCE}>
                Salesforce
              </MenuItem>
              <MenuItem value={SourceType.SALESFORCE_APPFLOW}>
                Salesforce via AppFlow
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={stepperPayload.prefix}
              error={!!errors.prefix?.message}
              helperText={errors.prefix?.message}
              label="Source Prefix"
              required
              fullWidth
              {...register('prefix', { required: true })}
            />
          </Grid>
        </Grid>
        <Box className="control-box">
          {(handleBack as unknown) ? (
            <Button onClick={onBack} color="inherit">
              Back
            </Button>
          ) : null}
          <Box className="control-box-separator" />
          <Button type="submit">Next</Button>
        </Box>
      </Box>
    </>
  );
};

export default RuleBuilderPatterns;
