import { Box, Chip, Modal, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  listEventRuleApplications,
  patchEventRuleApplicationApprovalStatus,
} from '../api/rule-application';

import { AxiosError } from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { RootState } from '../redux/store';
import { useAppSelector } from '../hooks';

// TODO selectedRuleApplication should be typed

// type ruleApplication = {
//   approvalStatus?: string;
//   creationStatus?: string;
//   evenRuleObject?: any;
//   eventBusObject?: any;
//   expirationDate: number;
//   id?: string;
//   ownerAccountName?: string;
//   ownerEmail?: string;
//   requestDate?: number;
//   reviewNote?: string;
//   creationNote?: string;
// };

const RuleApplicationExtendViewModal = (props: {
  selectedRuleApplication: any;
  modalOpen: boolean;
  setModalOpen: any;
  setSelectedRuleApplication: any;
}) => {
  const {
    selectedRuleApplication,
    setSelectedRuleApplication,
    modalOpen,
    setModalOpen,
  } = props;

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // Store rejection reason in the state
  const [reviewNote, setReviewNote] = useState('-');

  const handleApprove = () => {
    patchEventRuleApplicationApprovalStatus(selectedRuleApplication.id, {
      approvalStatus: 'approved',
      reviewNote,
    })
      .catch((rej: AxiosError<{ message: string }>) => {
        alert(rej.response?.data.message);
      })
      .finally(() => {
        setModalOpen(false);
        setSelectedRuleApplication({
          ...selectedRuleApplication,
          approvalStatus: 'approved',
          reviewNote,
        });
      });
  };

  const handleReject = () => {
    patchEventRuleApplicationApprovalStatus(selectedRuleApplication.id, {
      approvalStatus: 'rejected',
      reviewNote,
    })
      .catch((rej: AxiosError<{ message: string }>) => {
        alert(rej.response?.data.message);
      })
      .finally(() => {
        setModalOpen(false);
        setSelectedRuleApplication({
          ...selectedRuleApplication,
          approvalStatus: 'rejected',
          reviewNote,
        });
      });
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography sx={{ p: '20px 0 10px 0' }} variant="h6" component="h3">
          Business Justification for{' '}
          {selectedRuleApplication && selectedRuleApplication?.action}
        </Typography>
        <Typography id="modal-modal-description" sx={{ p: '10px 0 20px 0' }}>
          {selectedRuleApplication &&
            selectedRuleApplication?.businessJustification}
        </Typography>

        <Typography id="modal-modal-title" variant="h6" component="h2">
          {selectedRuleApplication?.eventBusObject?.name}
        </Typography>
        <Typography variant="h6" component="h3">
          Review Note
        </Typography>

        {selectedRuleApplication?.approvalStatus === 'pending' ? (
          <>
            <Box sx={{ p: '20px 0' }}>
              <TextField
                multiline
                onChange={(e) => setReviewNote(e.target.value)}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box>
              <Chip
                sx={{ mr: '10px' }}
                color="success"
                label="Approve"
                onClick={handleApprove}
              />
              <Chip
                sx={{ mr: '10px' }}
                color="error"
                label="Reject"
                onClick={handleReject}
              />
            </Box>
          </>
        ) : selectedRuleApplication?.approvalStatus === 'approved' ? (
          <>
            <Typography
              id="modal-modal-description"
              sx={{ p: '10px 0 20px 0' }}
            >
              {selectedRuleApplication?.reviewNote}
            </Typography>
            <Chip color="success" label="Approved" />
          </>
        ) : (
          <>
            <Typography
              id="modal-modal-description"
              sx={{ p: '10px 0 20px 0' }}
            >
              {selectedRuleApplication?.reviewNote}
            </Typography>
            <Chip color="error" label="Rejected" />
          </>
        )}
        <Typography variant="h6" component="h3" sx={{ mt: '10px' }}>
          Creation Status
        </Typography>
        <Typography id="modal-modal-description" sx={{ p: '10px 0 20px 0' }}>
          {selectedRuleApplication?.creationNote}
        </Typography>
        {selectedRuleApplication?.status === 'succeeded' ? (
          <Chip color="success" label="Succeeded" />
        ) : selectedRuleApplication?.status === 'failed' ? (
          <Chip color="error" label="Failed" />
        ) : (
          <Chip color="warning" label="Pending" />
        )}
      </Box>
    </Modal>
  );
};

const RuleAdmin = () => {
  const { userinfo } = useAppSelector((state: RootState) => state.auth);

  const convertDateFormat = (date: Date) => {
    if (date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${day}.${month}.${date.getFullYear()}`;
    } else {
      return '-';
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'requestDate',
      type: 'date',
      headerName: 'Request Date',
      valueGetter: (params) => new Date(params.row.requestDate * 1000),
      valueFormatter: (params) => convertDateFormat(params.value),
      minWidth: 150,
    },
    {
      field: 'action',
      headerName: 'Application Action',
      valueGetter: (params) => params.row.action,
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: 'Event Rule Name',
      valueGetter: (params) => params.row.eventRuleObject.name,
      minWidth: 200,
    },
    {
      field: 'eventBusName',
      headerName: 'Event Bus Name',
      valueGetter: (params) => params.row.eventRuleObject.eventBusName,
      minWidth: 200,
    },
    {
      field: 'ownerAccountName',
      headerName: 'Owner MPS Tenant',
      minWidth: 150,
    },
    {
      field: 'ownerEmail',
      headerName: 'Owner Email',
      minWidth: 250,
    },
    {
      field: 'approvalStatus',
      headerName: 'Approval Status',
      minWidth: 150,
      renderCell: (params) => {
        return params.row.approvalStatus === 'pending' ? (
          <Chip color="warning" label="Pending" />
        ) : params.row.approvalStatus === 'approved' ? (
          <Chip color="success" label="Approved" />
        ) : (
          <Chip color="error" label="Rejected" />
        );
      },
    },
    {
      field: 'reviewDate',
      headerName: 'Review Date',
      valueGetter: (params) =>
        params.row.reviewDate
          ? new Date(params.row.reviewDate * 1000)
          : undefined,
      valueFormatter: (params) => convertDateFormat(params.value),
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => {
        return params.row.status === 'pending' ? (
          <Chip color="warning" label="Pending" />
        ) : params.row.status === 'succeeded' ? (
          <Chip color="success" label="Succeeded" />
        ) : params.row.status === 'canceled' ? (
          <Chip color="error" label="Canceled" />
        ) : (
          <Chip color="error" label="Failed" />
        );
      },
    },
  ];

  // Use state to store the rows
  const [rows, setRows] = useState([]);
  const [selectedRuleApplication, setSelectedRuleApplication] = useState({
    name: '',
    businessJustification: '',
  });
  const [modalOpen, setModalOpen] = useState(false);

  // Get all existing bus applications.
  // No need to use redux store, everything is encapsulated withing this very page.
  useEffect(() => {
    listEventRuleApplications()
      .then((serviceResponse) => setRows(serviceResponse))
      .catch((err) => {
        alert(err.message);
      });
  }, [selectedRuleApplication]);

  const handleRowClick = (params: any) => {
    // Set the currently selected application
    setSelectedRuleApplication(params.row);
    // Open modal view
    setModalOpen(true);
  };

  return (
    <>
      {/*
        This is the main grid responsible for rendering all bus applications.
        It does not contain a business justification field.
      */}

      {userinfo?.groups.filter(
        (group) =>
          group.tenantName === localStorage.getItem('selectedTenant') &&
          group.role !== 'readonly'
      ).length ? (
        <DataGrid
          // Since the row is clickable, use pointer style cursor
          sx={{ '& .MuiDataGrid-row:hover': { cursor: 'pointer' } }}
          // Progress bar loader. Non functionalal
          slots={{ loadingOverlay: LinearProgress }}
          loading={!rows.length}
          columns={columns}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: 'requestDate', sort: 'desc' }],
            },
          }}
          // Open extended view modal on click
          onRowClick={handleRowClick}
        />
      ) : (
        <div>You do not have aprover level access to this tenancy</div>
      )}

      {/*
        This is the extended view modal window where one can see an extended overview of the selected application.
      */}
      <RuleApplicationExtendViewModal
        setSelectedRuleApplication={setSelectedRuleApplication}
        selectedRuleApplication={selectedRuleApplication}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default RuleAdmin;
