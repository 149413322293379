import {
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import Box from '@mui/material/Box';
import { Buffer } from 'buffer';
import CreationAlert from './CreationAlert';
import DefineSchema from './DefineSchema';
import { addSchemasToBus } from '../../../redux/actions/AlignmentActions';
import { createSchema } from '../../../api/client';
import templateJsonschema from '../../../static/schemaTemplateJsonschema.json';
import templateOpenapi from '../../../static/schemaTemplateOpenapi.json';

type Props = {
  creationClose: () => void;
};

const SchemaAdd: React.FC<Props> = ({ creationClose }) => {
  const [schemaOpenapi, setSchemaOpenapi] = React.useState(
    JSON.stringify(templateOpenapi, null, 2)
  );
  const [schemaJsonschema, setSchemaJsonschema] = React.useState(
    JSON.stringify(templateJsonschema, null, 2)
  );
  const [selectedSchemaType, setSelectedSchemaType] = React.useState('openApi');
  const [textError, setTextError] = React.useState(false);
  const [schemaName, setSchemaName] = React.useState('');

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSuccess, setSnackbarSuccess] = React.useState(false);

  const dispatch = useAppDispatch();

  const selectedBus = useAppSelector((state) => state.alignment.bus);
  const allBuses = useAppSelector((state) => state.alignment.busses);
  const creator = useAppSelector((state) => state.auth.userinfo?.email);
  const validateJson = (jsonString: string): boolean => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  };

  const handleSchemaChange = (value: string | undefined) => {
    if (value) {
      selectedSchemaType === 'openApi'
        ? setSchemaOpenapi(value)
        : setSchemaJsonschema(value);
    }
  };

  const handleSelectionChange = (
    event: React.MouseEvent<HTMLElement>,
    newSchema: string
  ) => {
    if (newSchema !== null) {
      setSelectedSchemaType(newSchema);
    }
  };

  const handleCreate = () => {
    const selectedTenant = window.localStorage.getItem('selectedTenant') ?? '';
    const eventBusName = selectedBus?.name ?? '';
    const schema =
      selectedSchemaType === 'openApi'
        ? Buffer.from(schemaOpenapi).toString('base64')
        : Buffer.from(schemaJsonschema).toString('base64');
    const schemaType =
      selectedSchemaType === 'openApi' ? 'OpenApi3' : 'JSONSchemaDraft4';
    let tags = allBuses.find((bus) => bus.name === eventBusName)?.tags;
    if (tags) {
      tags = [...tags];
    }
    tags?.push({ key: 'events:creator', value: creator ?? '' });
    createSchema(
      selectedTenant,
      eventBusName,
      schema,
      schemaType,
      schemaName,
      tags
    )
      .then((result) => {
        setSnackbarSuccess(true);
        setSnackbarMessage(
          `The schema ${schemaName} has successfully been created with version ${result.data.schemaVersion}`
        );
        setShowSnackbar(true);
        if (selectedBus) {
          const newBus = { ...selectedBus };
          newBus.schemas = selectedBus.schemas
            ? [result.data.schemaName, ...selectedBus.schemas]
            : [result.data.schemaName];
          dispatch(addSchemasToBus(newBus));
        }
        creationClose();
      })
      .catch((err) => {
        setSnackbarSuccess(false);
        setSnackbarMessage(
          `Your schema could not have been created, receiving following error: ${
            err.response?.data?.message ?? err.message
          }`
        );
        setShowSnackbar(true);
      });
  };

  const validateText = (event: ChangeEvent<HTMLInputElement>) => {
    const schemaRegex = /^[a-zA-Z][a-zA-Z0-9-_]{0,254}$/;
    if (!schemaRegex.test(event.target.value)) {
      setTextError(true);
    } else {
      setTextError(false);
      setSchemaName(event.target.value);
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleReset = () => {
    setSchemaOpenapi(JSON.stringify(templateOpenapi, null, 2));
    JSON.stringify(templateJsonschema, null, 2);
  };

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <TextField
          id="outlined-basic"
          label="provide a name for your schema"
          variant="outlined"
          fullWidth
          onChange={validateText}
          sx={{ marginBottom: '5px' }}
          error={textError}
          helperText={
            textError
              ? 'The schema name must only contain "-","_" or alphanumeric characters and cannot be longer then 255 characters'
              : ''
          }
        />
        <ToggleButtonGroup
          color="primary"
          value={selectedSchemaType}
          exclusive
          onChange={handleSelectionChange}
          aria-label="Platform"
          sx={{ justifyContent: 'center', m: '10px' }}
        >
          <ToggleButton value="openApi">OpenApi</ToggleButton>
          <ToggleButton value="jsonSchema">Json Schema</ToggleButton>
        </ToggleButtonGroup>
        <DefineSchema
          schema={
            selectedSchemaType === 'openApi' ? schemaOpenapi : schemaJsonschema
          }
          handleChange={handleSchemaChange}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '20%', width: '100%', mt: '10px' }}>
        <Button
          color="inherit"
          variant="outlined"
          onClick={handleReset}
          sx={{ mt: 1, width: '100%' }}
        >
          Reset Schemas
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          onClick={handleCreate}
          sx={{ mt: 1, width: '100%' }}
          disabled={
            textError ||
            schemaName === '' ||
            (selectedSchemaType === 'openApi'
              ? !validateJson(schemaOpenapi)
              : !validateJson(schemaJsonschema))
          }
        >
          Create Schema
        </Button>
      </Box>
      <CreationAlert
        open={showSnackbar}
        onClose={handleSnackbarClose}
        success={snackbarSuccess}
        message={snackbarMessage}
      />
    </Box>
  );
};
export default SchemaAdd;
