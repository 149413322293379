import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object as zObject, string as zString, TypeOf as zTypeOf } from 'zod';
import { EventBusBuilderStepProps } from '../bus-builder';

// Create a schema and the type for our form
const formConfigSchema = zObject({
  name: zString()
    .min(1, 'Name is required')
    .max(
      256,
      'Maximum of 256 characters consisting of numbers, lower/upper case letters, .,-,_.'
    ),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const BusBuilderDetails: React.FC<EventBusBuilderStepProps> = ({
  stepperPayload,
  setStepperPayload,
  handleNext,
}: EventBusBuilderStepProps) => {
  // Get all form handlers via useForm
  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<FormConfig>({
    defaultValues: {
      name: stepperPayload.eventBusObject.name,
    },
    resolver: zodResolver(formConfigSchema),
  });

  const onNext: SubmitHandler<FormConfig> = ({ name }) => {
    stepperPayload.eventBusObject.name = name;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Box>
          <TextField
            fullWidth
            label="Event Bus Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            defaultValue={defaultValues?.name}
            {...register('name')}
          />
        </Box>
        <Box className="control-box">
          <Box className="control-box-separator" />
          <Button type="submit">Next</Button>
        </Box>
      </Box>
    </>
  );
};

export default BusBuilderDetails;
