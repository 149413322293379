import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Handle, Position } from 'reactflow';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EventBusUpdater from '../bus/bus-updater';
import InfoIcon from '@mui/icons-material/Info';
import RuleBuilder from '../builders/rules/rule-builder';
import { Tag } from '../../model/Bus';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { selectTagsByBus } from '../../redux/selectors/busSelector';
import { deleteBus } from '../../redux/actions/BusActions';
import { RequestState } from '../../model/requestState';
import { DELETE_BUS_RESET } from '../../redux/actions/actionTypes';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
};

const circularSpinner = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const BusNode: React.FC = () => {
  const dispatch = useAppDispatch();

  const bus = useAppSelector((state) => state.alignment.bus);
  const rules = useAppSelector((state) => state.alignment.rules);
  const tags = useAppSelector((state) => selectTagsByBus(state, bus?.name));

  const [open, setOpen] = useState(false);
  const [openUpdates, setOpenUpdates] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdate = () => setOpenUpdates(true);
  const handleCloseUpdates = () => setOpenUpdates(false);

  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => {
    setOpenDelete(false);
    dispatch({
      type: DELETE_BUS_RESET,
    });
  };

  const handleBusDeletion = () => {
    dispatch(
      deleteBus(bus?.name || '', localStorage.getItem('selectedTenant') || '')
    );
  };

  const [forbiddenMessage, setForbiddenMessage] = useState('');
  const [deletionForbidden, setDeletionForbidden] = useState(false);

  const forbidDeletion = () => {
    const busTenant = tags?.find((tag) => tag.key === 'events_tenant_owner');
    if (rules && rules.length) {
      setForbiddenMessage(
        'The bus you are trying to delete still contains rules. All rules need to be removed before deleting the bus.'
      );
      setDeletionForbidden(true);
      return;
    } else if (
      busTenant?.value !== localStorage.getItem('selectedTenant') ||
      ''
    ) {
      setForbiddenMessage(
        `The tenant you selected is not the tenant this bus was created with. You need to be in the tenant ${busTenant?.value} to delete the bus.`
      );
      setDeletionForbidden(true);
      return;
    }
    setDeletionForbidden(false);
  };

  useEffect(() => {
    forbidDeletion();
    // static function, does not require exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDelete]);

  const [visibilityTag, setVisibilityTag] = useState<Tag>();
  const [ownerTag, setOwnerTag] = useState<Tag>();
  const [tenantTag, setTenantTag] = useState<Tag>();
  const busDeletionRequestState = useAppSelector(
    (state) => state.bus.requestStateDeleteBus
  );
  const busDeletionError = useAppSelector((state) => state.bus.deletionError);

  useEffect(() => {
    if (Array.isArray(tags)) {
      const _visibilityTag = tags.find(
        (tag: Tag) => tag.key === 'events_visibility'
      );
      setVisibilityTag(_visibilityTag);

      setOwnerTag(tags.find((tag: Tag) => tag.key === 'events_creator'));
      setTenantTag(tags.find((tag: Tag) => tag.key === 'events_tenant_owner'));
    }
  }, [tags]);

  return (
    <>
      <div
        style={{
          height: '130px',
          width: '220px',
          background: 'white',
          textAlign: 'center',
          borderRadius: '4px',
          border: 'solid 1px grey',
        }}
      >
        <div
          style={{
            height: '40px',
            background: 'rgb(209,112,42)',
            paddingTop: '1px',
            marginBottom: '5%',
            color: 'white',
            textDecoration: 'bolder',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px 3px 0px 0px',
          }}
        >
          <Typography variant="body1" sx={{ verticalAlign: 'middle' }}>
            {bus?.name}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                onClick={handleOpen}
                variant="outlined"
                size="small"
                color="success"
              >
                <AddIcon></AddIcon>
                Add Rule
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleOpenInfo} variant="outlined" size="small">
                Info
                <InfoIcon></InfoIcon>
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleUpdate} variant="outlined" size="small">
                <UpgradeIcon></UpgradeIcon>
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleOpenDelete}
                variant="outlined"
                size="small"
                color="error"
              >
                Delete
                <DeleteIcon></DeleteIcon>
              </Button>
            </Grid>
          </Grid>
        </div>
        {/* Add Update and details buttons */}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RuleBuilder
            setModalStatus={setOpen}
            existingRuleNames={rules?.map((rule) => rule.name)}
          />
        </Box>
      </Modal>
      <Modal
        open={openUpdates}
        onClose={handleCloseUpdates}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EventBusUpdater setModalStatus={setOpenUpdates} />
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {busDeletionRequestState !== RequestState.INITIAL &&
        busDeletionRequestState !== RequestState.FETCHING ? (
          <Box
            sx={{ ...style, width: 768 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={3}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
          >
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {busDeletionRequestState === RequestState.ERROR
                ? 'Request Failed'
                : 'Success'}
            </Typography>
            <Typography variant="body1">
              {busDeletionError || 'Your bus has successfully been deleted.'}
            </Typography>
            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => {
                dispatch({
                  type: DELETE_BUS_RESET,
                });
                setOpenDelete(false);
              }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <>
            {busDeletionRequestState === RequestState.FETCHING ? (
              <Box
                sx={{ ...style, height: 200, width: 768 }}
                alignItems="center"
                justifyContent="center"
                p={3}
                border={1}
                borderColor="grey.300"
                borderRadius={4}
              >
                <Typography variant="h5">Deleting Bus...</Typography>
                <CircularProgress sx={circularSpinner} />
              </Box>
            ) : (
              <Box
                sx={{ ...style, width: 768 }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={3}
                border={1}
                borderColor="grey.300"
                borderRadius={4}
              >
                <Typography variant="h5">
                  {forbiddenMessage === ''
                    ? `Do you really want to delete ${bus?.name}? This action cannot be undone.`
                    : forbiddenMessage}
                </Typography>
                {deletionForbidden === true ? (
                  <Button
                    variant="contained"
                    onClick={() => setOpenDelete(false)}
                    sx={{ marginTop: '10px' }}
                  >
                    Close
                  </Button>
                ) : (
                  <Box mt={2} sx={{ '& > button': { marginRight: '8px' } }}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDelete(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleBusDeletion}
                      disabled={deletionForbidden}
                    >
                      Confirm
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Modal>
      <Modal
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby="modal-modal-info"
        aria-describedby="modal-modal-info-description"
      >
        <Box sx={{ ...style, width: 768 }}>
          <Typography id="server-modal-title" variant="h6" component="h2">
            <strong>{bus?.name}</strong>
            <Chip
              label={visibilityTag?.value}
              color={visibilityTag?.value === 'public' ? 'success' : 'warning'}
              sx={{ ml: 0.5 }}
            />
          </Typography>
          <small>{bus?.arn}</small>
          <Card variant="outlined" sx={{ mt: 2 }}>
            <CardContent>
              <Stack spacing={2} sx={{ width: '50%' }}>
                <TextField
                  disabled
                  id="outlined-uncontrolled"
                  label="Tenant"
                  size="small"
                  variant="standard"
                  defaultValue={tenantTag?.value}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000000',
                    },
                  }}
                />
                {ownerTag ? (
                  <TextField
                    disabled
                    id="outlined-uncontrolled"
                    label="Owner"
                    size="small"
                    variant="standard"
                    defaultValue={ownerTag?.value}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                    }}
                  />
                ) : undefined}
              </Stack>

              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" component="h6">
                  Tags
                </Typography>

                {tags?.map((tag) => (
                  <Chip
                    sx={{ mr: 0.25, mb: 0.25 }}
                    key={tag.key}
                    label={
                      <>
                        {<strong>{tag.key}</strong>}: {tag.value}
                      </>
                    }
                  />
                ))}
              </Box>
            </CardContent>
          </Card>
          <Box sx={{ mt: 2 }}>
            <center>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleCloseInfo}
              >
                Close
              </Button>
            </center>
          </Box>
        </Box>
      </Modal>
      <Handle type="source" position={Position.Right} id="a" />
      <Handle type="target" position={Position.Left} id="b" />
    </>
  );
};

export default BusNode;
