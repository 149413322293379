/** @format */

const portal = {
  appConfig: {
    language: 'en',
    title: 'bff-event-portal',
  },
};

export default portal;

export const getStageConfig = () => {
  const region = 'eu-central-1';
  const stage = process.env.REACT_APP_ENV;

  let apiBasePath;
  if (stage === 'local') {
    apiBasePath = 'http://localhost:3000';
  } else if (stage === 'prod') {
    apiBasePath = 'https://api.events.vwfs.io';
  } else {
    apiBasePath = `https://api.${stage}.events.vwfs.io`;
  }

  const STAGE_CONFIG = {
    local: {
      portalBackendUrl: 'http://localhost:3000/events-portal-service',
    },
    dev: {
      portalBackendUrl:
        apiBasePath + `/events-portal-service/${region}/${stage}`,
    },
    int: {
      portalBackendUrl:
        apiBasePath + `/events-portal-service/${region}/${stage}`,
    },
    cons: {
      portalBackendUrl:
        apiBasePath + `/events-portal-service/${region}/${stage}`,
    },
    prod: {
      portalBackendUrl:
        apiBasePath + `/events-portal-service/${region}/${stage}`,
    },
  };

  const stageConfig = STAGE_CONFIG[stage];

  return {
    portalBackendUrl: stageConfig.portalBackendUrl,
    // locally the 'region' and 'stage' in the path will be removed by proxy in setupProxy.js
    authUrl: apiBasePath + `/events-portal-service/eu-central-1/${stage}`,
  };
};
