import { SnackBarActions } from '../actions/snackbarActionTypes';

export type SnackbarState = {
  notification?: {
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
  };
};

const initState: SnackbarState = {};

export default (state: SnackbarState = initState, action: SnackBarActions) => {
  switch (action.type) {
    case 'SHOW_MESSAGE_IN_SNACKBAR':
      return {
        ...state,
        notification: {
          ...action,
        },
      };

    default:
      return state;
  }
};
