import { Button, Stack } from '@mui/material';
import { Handle, Position } from 'reactflow';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import EditorPane from '../editor/Editor';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vh',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '4px',
};

interface SourceNodeProps {
  data: {
    label: string;
    eventPattern: string;
  };
}

const SourceNode: React.FC<SourceNodeProps> = ({ data }) => {
  const { label, eventPattern } = data;

  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  return (
    <>
      <div
        style={{
          height: '90px',
          width: '200px',
          background: 'white',
          textAlign: 'center',
          borderRadius: '4px',
          border: 'solid 1px grey',
        }}
      >
        <div
          style={{
            height: '40px',
            background: 'rgb(90, 100, 200)',
            paddingTop: '1px',
            marginBottom: '5%',
            color: 'white',
            textDecoration: 'bolder',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px 3px 0px 0px',
          }}
        >
          <Typography variant="body1" sx={{ verticalAlign: 'middle' }}>
            {label}
          </Typography>
        </div>
        <Button onClick={handleOpenInfo} variant="outlined" size="small">
          Details
        </Button>
      </div>

      <Modal
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby="modal-modal-info"
        aria-describedby="modal-modal-info-description"
      >
        <Box sx={style}>
          <Stack spacing={0}>
            <Typography
              id="modal-modal-createdBy"
              variant="subtitle2"
              component="p"
            >
              <strong>The event pattern used to define the sources:</strong>
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <EditorPane
                height="45vh"
                width="80vh"
                value={eventPattern}
                readOnly
              />
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Handle type="source" position={Position.Right} id="a" />
    </>
  );
};

export default SourceNode;
