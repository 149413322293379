/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ZodIssueCode,
  boolean as zBoolean,
  object as zObject,
  record as zRecord,
  string as zString,
  TypeOf as zTypeOf,
} from 'zod';
import { RuleBuilderStepProps } from '../rule-builder';

import { zodResolver } from '@hookform/resolvers/zod';
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { v4 } from 'uuid';
import { TargetRequest } from '../../../page-layout/RuleNode';
import { registerError } from '../../utils';

export interface TargetProps {
  target: TargetRequest;
  targetsLength?: number;
  onUpdate: (target: TargetRequest) => void;
  onDelete?: (key: string) => void;
  register: any;
  errors: any;
}

// schema for input collection
const formConfigSchema = zObject({
  targets: zRecord(
    zString(),
    zObject({
      // index: zString(),
      targetName: zString()
        .min(1, 'Target name is required')
        .regex(/^([.\-_A-Za-z0-9]+)$/, 'Please provide an AWS-compliant name')
        .max(41, 'The name must not exceed 41 characters'), // Only 41 digits are allowed, because the be create the following naming convention {10 char hash}-{targetName}-destination and aws only allowed 64 chars for target names
      targetDescription: zString().optional(),
      targetType: zString().default('HTTP'),
      targetOrigin: zString().default('new'),
      targetToDelete: zBoolean().default(false),
      httpTargetProps: zObject({
        endpoint: zString()
          .startsWith('https://', 'Only HTTPS endpoints are accepted')
          .min(10, 'Only HTTPS endpoints are accepted'),
        httpMethod: zString().default('GET'),
        authorizationType: zString().default('API_KEY'),
        apiKeyParams: zObject({
          apiKeyName: zString().nonempty(
            'An API Key header name has to be provided'
          ),
          apiKeyValue: zString().nonempty(
            'An API Key header value has to be provided'
          ),
        }).optional(),
        oauthParams: zObject({
          authorizationEndpoint: zString().nonempty(
            'OIDC Authorization Endpoint has to be provided'
          ),
          clientId: zString().nonempty('OIDC Client Id has to be provided'),
          clientSecret: zString().nonempty(
            'OIDC Client Secret has to be provided'
          ),
          username: zString().optional(),
          password: zString().optional(),
        }).optional(),
      })
        .optional()
        .superRefine((input, refinementContext) => {
          if (input?.authorizationType === 'OAUTH_USERNAME_PASSWORD_FLOW') {
            if (
              input.oauthParams?.username === undefined ||
              input.oauthParams?.username === ''
            ) {
              refinementContext.addIssue({
                code: ZodIssueCode.custom,
                message:
                  'A username has to be provided for this authorization type.',
                path: ['oauthParams', 'username'],
              });
            }
            if (
              input.oauthParams?.password === undefined ||
              input.oauthParams?.password === ''
            ) {
              refinementContext.addIssue({
                code: ZodIssueCode.custom,
                message:
                  'A password has to be provided for this authorization type.',
                path: ['oauthParams', 'password'],
              });
            }
          }
        }),
      eventBusTargetProps: zObject({
        targetEventBusName: zString().optional(),
        targetEventBusArn: zString()
          .nonempty('Target Event Bus ARN has to be provided')
          .regex(
            /^arn:aws:events:\w+(?:-\w+)+:\d{12}:event-bus\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/,
            'Please provide an AWS-compliant arn'
          ),
      }).optional(),
    })
  ),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const RuleBuilderTargets: React.FC<RuleBuilderStepProps> = ({
  stepperPayload,
  setStepperPayload,
  handleNext,
  handleBack,
  maxAllowedTargets,
}) => {
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormConfig>({
    defaultValues: {
      targets: stepperPayload.eventRuleObject.targets,
    },
    resolver: zodResolver(formConfigSchema),
  });

  const [targetLength, setTargetLength] = useState(
    Object.keys(stepperPayload.eventRuleObject.targets).length
  );

  const debug = () => {
    console.table(errors);
  };

  const onNext: SubmitHandler<FormConfig> = ({ targets }) => {
    stepperPayload.eventRuleObject.targets = targets as any;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    const targets = getValues('targets');
    stepperPayload.eventRuleObject.targets = targets as any;
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  const addTarget = () => {
    if (targetLength >= (maxAllowedTargets as number)) {
      alert('There cannot be more then five targets per rule in total.');
      return;
    }
    stepperPayload.eventRuleObject.targets[v4()] = {
      targetType: 'HTTP',
      httpTargetProps: {
        authorizationType: 'API_KEY',
        httpMethod: 'GET',
        endpoint: 'https://',
        apiKeyParams: {
          apiKeyName: '',
          apiKeyValue: '',
        },
      },
    };
    setStepperPayload({ ...stepperPayload });
    setTargetLength(Object.keys(stepperPayload.eventRuleObject.targets).length);
  };

  const removeTarget = (targetKey: string) => {
    if (
      stepperPayload.eventRuleObject.targets[targetKey].targetOrigin ===
      'existing'
    ) {
      stepperPayload.eventRuleObject.targets[targetKey].targetToDelete = true;
    } else {
      delete stepperPayload.eventRuleObject.targets[targetKey];
      setTargetLength(targetLength - 1);
    }
    setStepperPayload({ ...stepperPayload });
    setValue('targets', stepperPayload.eventRuleObject.targets as any);
  };

  const reinstateTarget = (targetKey: string) => {
    delete stepperPayload.eventRuleObject.targets[targetKey].targetToDelete;
    setStepperPayload({ ...stepperPayload });
    setValue('targets', stepperPayload.eventRuleObject.targets as any);
  };

  const handleTargetTypeChange = (
    event: SelectChangeEvent,
    targetKey: string
  ) => {
    const currentTarget = stepperPayload.eventRuleObject.targets[targetKey];
    // Delete unwanted props
    if (event.target.value === 'EVENT_BUS') {
      delete currentTarget.httpTargetProps;
      currentTarget.eventBusTargetProps = {
        targetEventBusArn: '',
      };
    } else {
      delete currentTarget.eventBusTargetProps;
      currentTarget.httpTargetProps = {
        authorizationType: 'API_KEY',
        httpMethod: 'GET',
        endpoint: 'https://',
        apiKeyParams: {
          apiKeyName: '',
          apiKeyValue: '',
        },
      };
    }
    // Manually set the target Type
    currentTarget.targetType = event.target.value as 'EVENT_BUS' | 'HTTP';
    setStepperPayload({ ...stepperPayload });
    setValue(`targets.${targetKey}`, currentTarget as any);
  };

  const handleAuthTypeChange = (
    event: SelectChangeEvent,
    targetKey: string
  ) => {
    const currentTarget = getValues(`targets.${targetKey}`);
    if (currentTarget.httpTargetProps === undefined) return;
    // Delete unwanted props
    if (event.target.value === 'API_KEY') {
      delete currentTarget.httpTargetProps.oauthParams;
      currentTarget.httpTargetProps.apiKeyParams = {} as any;
    } else {
      delete currentTarget.httpTargetProps.apiKeyParams;
      currentTarget.httpTargetProps.oauthParams = {} as any;
    }
    // Manually set the target Type
    currentTarget.httpTargetProps.authorizationType = event.target.value as
      | 'API_KEY'
      | 'OAUTH_CLIENT_CREDENTIALS'
      | 'OAUTH_USERNAME_PASSWORD_FLOW';

    stepperPayload.eventRuleObject.targets[targetKey] = currentTarget as any;
    setStepperPayload({ ...stepperPayload });
    setValue(`targets.${targetKey}`, currentTarget as any);
  };

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    targetKey: string
  ) => {
    const currentTarget = stepperPayload.eventRuleObject.targets[targetKey];
    currentTarget.targetName = event.target.value;
    setStepperPayload({ ...stepperPayload });
    setValue(`targets.${targetKey}.targetName`, currentTarget.targetName, {
      shouldValidate: true,
    });
  };
  const renderTargetComponent = (target: TargetRequest, targetKey: string) => {
    if (target.targetOrigin !== 'existing') {
      return (
        <Box
          key={targetKey}
          sx={{
            '& > :not(style)': { mt: 1, mb: 1 },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h6" gutterBottom>
              {target.targetName || 'Unnamed Target'}
            </Typography>
            {targetLength > 1 ? (
              <IconButton
                edge="end"
                aria-label="Remove Target"
                onClick={() => removeTarget(targetKey)}
              >
                <RemoveCircleIcon />
              </IconButton>
            ) : null}
          </Box>
          <FormControl fullWidth>
            <InputLabel>Target type*</InputLabel>
            <Select
              label="Target type*"
              onChange={(e) => handleTargetTypeChange(e, targetKey)}
              defaultValue={target.targetType}
            >
              <MenuItem value="HTTP">HTTP Endpoint</MenuItem>
              <MenuItem value="EVENT_BUS">EventBridge API destination</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Name*"
            fullWidth
            key={`targets.${targetKey}.targetName`}
            // {...register(`targets.${targetKey}.targetName`)}
            onChange={(e) => handleNameChange(e, targetKey)}
            {...registerError(errors, `targets.${targetKey}.targetName`)}
            defaultValue={target.targetName ?? ''}
          />
          <TextField
            label="Description"
            fullWidth
            key={`targets.${targetKey}.targetDescription`}
            {...register(`targets.${targetKey}.targetDescription`)}
            {...registerError(errors, `targets.${targetKey}.targetDescription`)}
            defaultValue={target.targetDescription ?? ''}
          />
          {target.targetType === 'EVENT_BUS'
            ? renderEventBusTarget(target, targetKey)
            : renderHttpTarget(target, targetKey)}
        </Box>
      );
    } else {
      return (
        <Box
          key={targetKey}
          sx={{
            '& > :not(style)': { mt: 1, mb: 1 },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h6" gutterBottom>
              <div
                style={{
                  textDecoration: target.targetToDelete
                    ? 'Line-through'
                    : 'None',
                }}
              >
                {target.targetName} {target.targetType}
              </div>
            </Typography>
            {targetLength > 1 && !target.targetToDelete ? (
              <IconButton
                edge="end"
                aria-label="Remove Target"
                onClick={() => removeTarget(targetKey)}
              >
                <RemoveCircleIcon />
              </IconButton>
            ) : target.targetToDelete ? (
              <IconButton
                edge="end"
                aria-label="Remove Target"
                onClick={() => reinstateTarget(targetKey)}
              >
                <AddCircle />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      );
    }
  };

  const renderEventBusTarget = (target: TargetRequest, targetKey: string) => {
    return (
      <Box
        sx={{
          '& > :not(style)': { mt: 1, mb: 1 },
        }}
      >
        <TextField
          fullWidth
          label="Event Bus Arn*"
          key={`targets.${targetKey}.eventBusTargetProps.targetEventBusArn`}
          defaultValue={target.eventBusTargetProps?.targetEventBusArn ?? ''}
          {...register(
            `targets.${targetKey}.eventBusTargetProps.targetEventBusArn`
          )}
          {...registerError(
            errors,
            `targets.${targetKey}.eventBusTargetProps.targetEventBusArn`
          )}
        />
        <TextField
          fullWidth
          label="Event Bus Name"
          key={`targets.${targetKey}.eventBusTargetProps.targetEventBusName`}
          defaultValue={target.eventBusTargetProps?.targetEventBusName ?? ''}
          {...register(
            `targets.${targetKey}.eventBusTargetProps.targetEventBusName`
          )}
          {...registerError(
            errors,
            `targets.${targetKey}.eventBusTargetProps.targetEventBusName`
          )}
        />
      </Box>
    );
  };

  const renderHttpTarget = (target: TargetRequest, targetKey: string) => {
    return (
      <Box
        sx={{
          '& > :not(style)': { mt: 1, mb: 1 },
        }}
      >
        <TextField
          label="Endpoint*"
          fullWidth
          {...register(`targets.${targetKey}.httpTargetProps.endpoint`)}
          {...registerError(
            errors,
            `targets.${targetKey}.httpTargetProps.endpoint`
          )}
          defaultValue={target.httpTargetProps?.endpoint ?? 'https://'}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-standard-label">
            HTTP Method*
          </InputLabel>

          <Select
            label="HTTP Method*"
            {...register(`targets.${targetKey}.httpTargetProps.httpMethod`)}
            defaultValue={target.httpTargetProps?.httpMethod ?? 'GET'}
          >
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
            <MenuItem value="PATCH">PATCH</MenuItem>
            <MenuItem value="DELETE">DELETE</MenuItem>
            <MenuItem value="OPTIONS">OPTIONS</MenuItem>
            <MenuItem value="HEAD">HEAD</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Authorization type*</InputLabel>
          <Select
            label="Authorization type*"
            defaultValue={
              target.httpTargetProps?.authorizationType ?? 'API_KEY'
            }
            onChange={(e) => handleAuthTypeChange(e, targetKey)}
          >
            <MenuItem value="API_KEY">API Key</MenuItem>
            <MenuItem value="OAUTH_CLIENT_CREDENTIALS">
              OAuth Client Credentials
            </MenuItem>
            <MenuItem value="OAUTH_USERNAME_PASSWORD_FLOW">
              OAuth Username Password Flow
            </MenuItem>
          </Select>
        </FormControl>
        {target.httpTargetProps?.authorizationType === 'API_KEY' ? (
          <Box
            sx={{
              '& > :not(style)': { mt: 1, mb: 1, ml: 1, width: '99%' },
            }}
          >
            <TextField
              fullWidth
              label="API key Header Name*"
              key={`targets.${targetKey}.httpTargetProps.apiKeyParams.apiKeyName`}
              defaultValue={
                target.httpTargetProps?.apiKeyParams?.apiKeyName ?? ''
              }
              {...register(
                `targets.${targetKey}.httpTargetProps.apiKeyParams.apiKeyName`
              )}
              {...registerError(
                errors,
                `targets.${targetKey}.httpTargetProps.apiKeyParams.apiKeyName`
              )}
            />
            <TextField
              label="API key Header Value*"
              fullWidth
              key={`targets.${targetKey}.httpTargetProps.apiKeyParams.apiKeyValue`}
              defaultValue={
                target.httpTargetProps?.apiKeyParams?.apiKeyValue ?? ''
              }
              {...register(
                `targets.${targetKey}.httpTargetProps.apiKeyParams.apiKeyValue`
              )}
              {...registerError(
                errors,
                `targets.${targetKey}.httpTargetProps.apiKeyParams.apiKeyValue`
              )}
            />
          </Box>
        ) : (
          <Box
            sx={{
              '& > :not(style)': { mt: 1, mb: 1, ml: 1, width: '99%' },
            }}
          >
            <TextField
              fullWidth
              label="Authorization Endpoint"
              key={`targets.${targetKey}.httpTargetProps.oauthParams.authorizationEndpoint`}
              defaultValue={
                target.httpTargetProps?.oauthParams?.authorizationEndpoint ??
                'https://login.salesforce.com/services/oauth2/token'
              }
              {...register(
                `targets.${targetKey}.httpTargetProps.oauthParams.authorizationEndpoint`
              )}
              {...registerError(
                errors,
                `targets.${targetKey}.httpTargetProps.oauthParams.authorizationEndpoint`
              )}
            />
            <TextField
              fullWidth
              label="OAuth Client Id"
              key={`targets.${targetKey}.httpTargetProps.oauthParams.clientId`}
              defaultValue={target.httpTargetProps?.oauthParams?.clientId ?? ''}
              {...register(
                `targets.${targetKey}.httpTargetProps.oauthParams.clientId`
              )}
              {...registerError(
                errors,
                `targets.${targetKey}.httpTargetProps.oauthParams.clientId`
              )}
            />
            <TextField
              fullWidth
              label="Oauth Client Secret"
              key={`targets.${targetKey}.httpTargetProps.oauthParams.clientSecret`}
              defaultValue={
                target.httpTargetProps?.oauthParams?.clientSecret ?? ''
              }
              {...register(
                `targets.${targetKey}.httpTargetProps.oauthParams.clientSecret`
              )}
              {...registerError(
                errors,
                `targets.${targetKey}.httpTargetProps.oauthParams.clientSecret`
              )}
            />
            {target.httpTargetProps?.authorizationType ===
            'OAUTH_USERNAME_PASSWORD_FLOW' ? (
              <Box>
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="OAuth Parameter Username"
                  key={`targets.${targetKey}.httpTargetProps.oauthParams.username`}
                  defaultValue={
                    target.httpTargetProps?.oauthParams?.username ?? ''
                  }
                  {...register(
                    `targets.${targetKey}.httpTargetProps.oauthParams.username`
                  )}
                  {...registerError(
                    errors,
                    `targets.${targetKey}.httpTargetProps.oauthParams.username`
                  )}
                />
                <TextField
                  fullWidth
                  label="OAuth Parameter Password"
                  key={`targets.${targetKey}.httpTargetProps.oauthParams.password`}
                  defaultValue={
                    target.httpTargetProps?.oauthParams?.password ?? ''
                  }
                  {...register(
                    `targets.${targetKey}.httpTargetProps.oauthParams.password`
                  )}
                  {...registerError(
                    errors,
                    `targets.${targetKey}.httpTargetProps.oauthParams.password`
                  )}
                />
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      maxHeight="70vh"
      overflow="auto"
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onNext, debug)}
    >
      <></>
      <Box display="flex" flexDirection="column" marginBottom={2}>
        {Object.entries(stepperPayload.eventRuleObject.targets).map(
          ([targetKey, target]) => renderTargetComponent(target, targetKey)
        )}
      </Box>
      <Box display="flex" justifyContent="center" marginTop={2}>
        {Object.values(targetLength).length < (maxAllowedTargets as number) ? (
          <Button variant="outlined" onClick={addTarget}>
            Add Additional Target
          </Button>
        ) : null}
      </Box>
      <Box className="control-box">
        <Button onClick={onBack} color="inherit">
          Back
        </Button>
        <Box className="control-box-separator" />
        <Button type="submit">Next</Button>
      </Box>
    </Box>
  );
};

export default RuleBuilderTargets;
