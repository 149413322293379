import Login from './auth/Login';
import React from 'react';
import 'reactflow/dist/style.css';
import { Box, Container } from '@mui/material';

const UnauthenticatedApp: React.FC = () => {
  return (
    <Container
      style={{
        margin: '15% auto auto',
        width: '400px',
        height: '300px',
        borderColor: '#fff',
        borderRadius: '16px',
        boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.2)',
        marginBottom: '15%',
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          src="bff-logo.png"
          alt="bff-events"
          style={{
            height: 'auto',
            width: '250px',
            margin: '25px 0px 20px 0px',
          }}
        />
      </Box>
      <Login />
    </Container>
  );
};

export default UnauthenticatedApp;
