import { Alert, Snackbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useAppSelector } from '../hooks';
import Header from './general/Header';
import HeaderTop from './general/HeaderTop';
import MainPage from './page-layout/MainPage';

const AuthenticatedApp: React.FC = () => {
  const notification = useAppSelector(
    (state) => state.notification.notification
  );

  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ position: 'absolute', top: 0, left: 0 }}
      >
        <Alert severity={notification?.severity} sx={{ width: '100%' }}>
          <Typography>{notification?.message}</Typography>
        </Alert>
      </Snackbar>
      <HeaderTop></HeaderTop>
      <Header />
      <MainPage />
    </div>
  );
};

export default AuthenticatedApp;
