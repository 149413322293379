/** @format */

import axios from 'axios';
import { getStageConfig } from '../config/portal';

export interface EventRuleApplicationRequest {
  [key: string]: any;
}

export interface EventRuleApplicationApprovalStatusRequest {
  approvalStatus: 'pending' | 'approved' | 'rejected';
  reviewNote?: string;
}

export async function postEventRuleApplication(
  eventBusApplicationRequest: EventRuleApplicationRequest
) {
  const result = await axios.post(
    `${getStageConfig().portalBackendUrl}/rule-applications`,
    eventBusApplicationRequest,
    { withCredentials: true }
  );
  return result.data;
}

export async function getEventRuleApplication(applicationId: string) {
  const result = await axios.get(
    `${getStageConfig().portalBackendUrl}/rule-applications/${applicationId}`,
    { withCredentials: true }
  );
  return result.data;
}

export async function patchEventRuleApplicationApprovalStatus(
  applicationId: string,
  eventBusApplicationApprovalStatusRequest: EventRuleApplicationApprovalStatusRequest
) {
  const result = await axios.patch(
    `${
      getStageConfig().portalBackendUrl
    }/rule-applications/${applicationId}/approval-status`,
    eventBusApplicationApprovalStatusRequest,
    { withCredentials: true }
  );
  return result.data;
}

export async function listEventRuleApplications(eventBusName?: string) {
  const result = await axios.get(
    `${getStageConfig().portalBackendUrl}/rule-applications?eventBusName=${
      eventBusName ?? ''
    }`,
    { withCredentials: true }
  );
  return result.data;
}
