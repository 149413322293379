/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object as zObject, number as zNumber, TypeOf as zTypeOf } from 'zod';
import { RuleBuilderStepProps } from '../rule-builder';

import { zodResolver } from '@hookform/resolvers/zod';
import { TargetRequest } from '../../../page-layout/RuleNode';

export interface TargetProps {
  target: TargetRequest;
  targetsLength?: number;
  onUpdate: (target: TargetRequest) => void;
  onDelete?: (key: string) => void;
  register: any;
  errors: any;
}

// schema for input collection
const formConfigSchema = zObject({
  retrySchedule: zNumber().optional(),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const RuleBuilderTargets: React.FC<RuleBuilderStepProps> = ({
  stepperPayload,
  setStepperPayload,
  handleNext,
  handleBack,
}) => {
  const { handleSubmit } = useForm<FormConfig>({
    resolver: zodResolver(formConfigSchema),
  });

  const [retrySchedule, setRetrySchedule] = useState(
    stepperPayload.eventRuleObject.retrySchedule
  );

  const [scheduleType, setScheduleType] = useState(
    stepperPayload.eventRuleObject.retrySchedule ? 'schedule' : 'default'
  );

  const onNext: SubmitHandler<FormConfig> = () => {
    stepperPayload.eventRuleObject.retrySchedule = retrySchedule as any;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    stepperPayload.eventRuleObject.retrySchedule = retrySchedule as any;
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  return (
    <Box
      maxHeight="70vh"
      overflow="auto"
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onNext)}
    >
      <Box>
        {/* The guaranteed delivery type selector */}
        <FormControl>
          <FormLabel id="guaranteed-delivery-config">Delivery type</FormLabel>
          <RadioGroup
            aria-labelledby="guaranteed-delivery-config"
            value={scheduleType}
            name="radio-buttons-group"
            onChange={(e) => {
              if (e.target.value === 'default') {
                setRetrySchedule(0);
              } else {
                setRetrySchedule(2);
              }
              setScheduleType(e.target.value);
            }}
          >
            <FormControlLabel
              value="default"
              control={<Radio />}
              label="Default"
            />
            <FormControlLabel
              value="schedule"
              control={<Radio />}
              label="Schedule"
            />
          </RadioGroup>
        </FormControl>

        {/* The guaranteed delivery type explanation */}
        <Box sx={{ padding: '10px 0px' }}>
          {scheduleType === 'default' ? (
            <Box>
              Default (24 hours/120 attempts): In cases where events cannot be
              delivered because of an EventBridge service disruption or some
              unexpected error on a consumers side, they will be retried again
              later by the AWS EventBridge for up to 24 hours/120 attempts.
            </Box>
          ) : (
            <Box>
              Schedule (unlimited): In cases where events cannot be delivered
              because of an EventBridge service disruption or some unexpected
              error on a consumers side, they will be retried again later within
              the configured schedule (rate) with no time limit.
            </Box>
          )}
        </Box>

        {/* The scheduled guaranteed delivery configuration */}
        {scheduleType === 'schedule' && retrySchedule ? (
          <Box sx={{ width: 250, padding: '10px 0px' }}>
            <Typography id="non-linear-slider" gutterBottom>
              Rate: {retrySchedule} {retrySchedule > 1 ? 'Hours' : 'Hour'}
            </Typography>
            <Slider
              min={1}
              max={24}
              step={1}
              value={retrySchedule}
              onChange={(event: Event, value: number | number[]) => {
                setRetrySchedule(value as number);
              }}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
              sx={{ width: 250, margin: '0px 10px' }}
            />
          </Box>
        ) : null}
      </Box>

      <Box className="control-box">
        <Button onClick={onBack} color="inherit">
          Back
        </Button>
        <Box className="control-box-separator" />
        <Button type="submit">Next</Button>
      </Box>
    </Box>
  );
};

export default RuleBuilderTargets;
