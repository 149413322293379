import Editor, { loader, OnMount } from '@monaco-editor/react';
import React from 'react';

loader.config({ paths: { vs: '/monaco-editor/min/vs' } });

type Props = {
  height: string;
  width: string;
  value?: string;
  onValueChange?: (value?: string) => void;
  defaultLanguage?: string;
  readOnly?: boolean;
  defaultValue?: string;
};

const EditorPane: React.FC<Props> = ({
  height,
  width,
  value,
  defaultValue,
  onValueChange,
  defaultLanguage = 'json',
  readOnly = false,
}) => {
  const handleEditorChange = (value?: string) => {
    if (typeof onValueChange === 'function') {
      onValueChange(value);
    }
  };

  const editorDidMount: OnMount = (editor) => {
    setTimeout(function () {
      if (editor.getAction('editor.action.formatDocument'))
        editor.getAction('editor.action.formatDocument')!.run();
    }, 100);
  };

  return (
    <>
      <Editor
        height={height}
        width={width}
        theme="vs-dark"
        defaultLanguage={defaultLanguage}
        defaultValue={defaultValue}
        value={value}
        onChange={handleEditorChange}
        onMount={editorDidMount}
        options={{
          automaticLayout: true,
          readOnly,
          minimap: {
            enabled: false,
          },
        }}
      />
    </>
  );
};

export default EditorPane;
