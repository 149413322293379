import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import {
  listEventBusApplications,
  patchEventBusApplicationApprovalStatus,
} from '../api/bus-application';
import { Box, Chip, Modal, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';

const BusApplicationExtendViewModal = (props: {
  selectedBusApplication: any;
  modalOpen: boolean;
  setModalOpen: any;
  setSelectedBusApplication: any;
}) => {
  const {
    selectedBusApplication,
    setSelectedBusApplication,
    modalOpen,
    setModalOpen,
  } = props;

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // Store rejection reason in the state
  const [reviewNote, setReviewNote] = useState('-');

  const handleApprove = () => {
    patchEventBusApplicationApprovalStatus(selectedBusApplication.id, {
      approvalStatus: 'approved',
      reviewNote,
    })
      .catch((rej: AxiosError<{ message: string }>) => {
        alert(rej.response?.data.message);
      })
      .finally(() => {
        setModalOpen(false);
        setSelectedBusApplication({
          ...selectedBusApplication,
          approvalStatus: 'approved',
          reviewNote,
        });
      });
  };

  const handleReject = () => {
    patchEventBusApplicationApprovalStatus(selectedBusApplication.id, {
      approvalStatus: 'rejected',
      reviewNote,
    })
      .catch((rej: AxiosError<{ message: string }>) => {
        alert(rej.response?.data.message);
      })
      .finally(() => {
        setModalOpen(false);
        setSelectedBusApplication({
          ...selectedBusApplication,
          approvalStatus: 'rejected',
          reviewNote,
        });
      });
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {selectedBusApplication?.eventBusObject?.name}
        </Typography>

        <Typography sx={{ p: '20px 0 10px 0' }} variant="h6" component="h3">
          Business Justification
        </Typography>
        <Typography id="modal-modal-description" sx={{ p: '10px 0 20px 0' }}>
          {selectedBusApplication &&
            selectedBusApplication?.businessJustification}
        </Typography>

        <Typography variant="h6" component="h3">
          Review Note
        </Typography>

        {selectedBusApplication?.approvalStatus === 'pending' ? (
          <>
            <Box sx={{ p: '20px 0' }}>
              <TextField
                multiline
                onChange={(e) => setReviewNote(e.target.value)}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box>
              <Chip
                sx={{ mr: '10px' }}
                color="success"
                label="Approve"
                onClick={handleApprove}
              />
              <Chip
                sx={{ mr: '10px' }}
                color="error"
                label="Reject"
                onClick={handleReject}
              />
            </Box>
          </>
        ) : selectedBusApplication?.approvalStatus === 'approved' ? (
          <>
            <Typography
              id="modal-modal-description"
              sx={{ p: '10px 0 20px 0' }}
            >
              {selectedBusApplication?.reviewNote}
            </Typography>
            <Chip color="success" label="Approved" />
          </>
        ) : (
          <>
            <Typography
              id="modal-modal-description"
              sx={{ p: '10px 0 20px 0' }}
            >
              {selectedBusApplication?.reviewNote}
            </Typography>
            <Chip color="error" label="Rejected" />
          </>
        )}
      </Box>
    </Modal>
  );
};

const BusAdmin = () => {
  const convertDateFormat = (date: Date) => {
    if (date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${day}.${month}.${date.getFullYear()}`;
    } else {
      return '-';
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'requestDate',
      type: 'date',
      headerName: 'Request Date',
      valueGetter: (params) => new Date(params.row.requestDate * 1000),
      valueFormatter: (params) => convertDateFormat(params.value),
      minWidth: 150,
    },
    {
      field: 'name',
      headerName: 'Event Bus Name',
      valueGetter: (params) => params.row.eventBusObject?.name,
      minWidth: 200,
    },
    {
      field: 'ownerAccountName',
      headerName: 'Owner MPS Tenant',
      minWidth: 150,
    },
    {
      field: 'ownerEmail',
      headerName: 'Owner Email',
      minWidth: 250,
    },
    {
      field: 'approvalStatus',
      headerName: 'Approval Status',
      minWidth: 150,
      renderCell: (params) => {
        return params.row.approvalStatus === 'pending' ? (
          <Chip color="warning" label="Pending" />
        ) : params.row.approvalStatus === 'approved' ? (
          <Chip color="success" label="Approved" />
        ) : (
          <Chip color="error" label="Rejected" />
        );
      },
    },
    {
      field: 'reviewDate',
      headerName: 'Review Date',
      valueGetter: (params) =>
        params.row.reviewDate
          ? new Date(params.row.reviewDate * 1000)
          : undefined,
      valueFormatter: (params) => convertDateFormat(params.value),
      minWidth: 150,
    },
    {
      field: 'creationStatus',
      headerName: 'Creation Status',
      minWidth: 150,
      renderCell: (params) => {
        return params.row.creationStatus === 'pending' ? (
          <Chip color="warning" label="Pending" />
        ) : params.row.creationStatus === 'succeeded' ? (
          <Chip color="success" label="Succeeded" />
        ) : params.row.creationStatus === 'canceled' ? (
          <Chip color="error" label="Canceled" />
        ) : (
          <Chip color="error" label="Failed" />
        );
      },
    },
  ];

  // Use state to store the rows
  const [rows, setRows] = useState([]);
  const [selectedBusApplication, setSelectedBusApplication] = useState({
    name: '',
    businessJustification: '',
  });
  const [modalOpen, setModalOpen] = useState(false);

  // Get all existing bus applications.
  // No need to use redux store, everything is encapsulated withing this very page.
  useEffect(() => {
    listEventBusApplications()
      .then((serviceResponse) => setRows(serviceResponse))
      .catch((err) => {
        alert(err.message);
      });
  }, [selectedBusApplication]);

  const handleRowClick = (params: any) => {
    // Set the currently selected application
    setSelectedBusApplication(params.row);
    // Open modal view
    setModalOpen(true);
  };

  return (
    <>
      {/*
        This is the main grid responsible for rendering all bus applications.
        It does not contain a business justification field.
      */}
      <DataGrid
        // Since the row is clickable, use pointer style cursor
        sx={{ '& .MuiDataGrid-row:hover': { cursor: 'pointer' } }}
        // Progress bar loader. Non functionalal
        slots={{ loadingOverlay: LinearProgress }}
        loading={!rows.length}
        columns={columns}
        rows={rows}
        initialState={{
          sorting: {
            sortModel: [{ field: 'requestDate', sort: 'desc' }],
          },
        }}
        // Open extended view modal on click
        onRowClick={handleRowClick}
      />

      {/*
        This is the extended view modal window where one can see an extended overview of the selected application.
      */}
      <BusApplicationExtendViewModal
        setSelectedBusApplication={setSelectedBusApplication}
        selectedBusApplication={selectedBusApplication}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default BusAdmin;
