import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { object as zObject, string as zString, TypeOf as zTypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { EventBusBuilderStepProps } from '../bus-builder';

// Create a schema and the type for our form
const formConfigSchema = zObject({
  businessJustification: zString()
    .nonempty(
      'You have to provide a comprehensive business justification. This step is very important so take your time with it!'
    )
    .min(
      100,
      'Please elaborate a little bit further. Min length is 100 characters!'
    ),
});
type FormConfig = zTypeOf<typeof formConfigSchema>;

const BusBuilderJustification = ({
  stepperPayload,
  setStepperPayload,
  handleBack,
  handleNext,
}: EventBusBuilderStepProps) => {
  // Get all form handlers via useForm
  const {
    control,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<FormConfig>({
    defaultValues: {
      businessJustification: stepperPayload.businessJustification,
    },
    resolver: zodResolver(formConfigSchema),
  });

  const [businessJustificationLength, setBusinessJustificationLength] =
    useState(stepperPayload.businessJustification?.length || 0);

  const onNext: SubmitHandler<FormConfig> = ({ businessJustification }) => {
    stepperPayload.businessJustification = businessJustification;
    setStepperPayload({ ...stepperPayload });
    handleNext();
  };

  const onBack = () => {
    stepperPayload.businessJustification = getValues().businessJustification;
    setStepperPayload({ ...stepperPayload });
    handleBack();
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onNext)}
      >
        <Box>
          <Controller
            rules={{ required: true }}
            control={control}
            name="businessJustification"
            render={() => (
              <>
                <TextField
                  fullWidth
                  rows={10}
                  multiline
                  label="Business Justification"
                  error={!!errors.businessJustification}
                  helperText={
                    (errors.businessJustification?.message ||
                      'Minimum length is 100 characters!') +
                    ` You have provided ${businessJustificationLength}`
                  }
                  defaultValue={defaultValues?.businessJustification}
                  onChange={(event) => {
                    setValue('businessJustification', event.target.value);
                    setBusinessJustificationLength(event.target.value.length);
                    if (businessJustificationLength > 100) {
                      trigger('businessJustification');
                    }
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box className="control-box">
          <Button onClick={onBack} color="inherit">
            Back
          </Button>
          <Box className="control-box-separator" />
          <Button type="submit">Request</Button>
        </Box>
      </Box>
    </>
  );
};

export default BusBuilderJustification;
