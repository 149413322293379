/* eslint-disable @typescript-eslint/no-non-null-assertion */

import './rule-builder.css';

import { Button, Typography } from '@mui/material';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { postEventRuleApplication } from '../../../api/rule-application';
import { useAppSelector } from '../../../hooks';
import { Bus } from '../../../model/Bus';
import { RuleStepperPayload, TargetRequest } from '../../page-layout/RuleNode';
import RuleBuilderDetails from './rule-builder-steps/step-1.details';
import RuleBuilderPatterns from './rule-builder-steps/step-2.patterns';
import RuleBuilderTargets from './rule-builder-steps/step-3.targets';
import RuleSchedule from './rule-builder-steps/step-4.schedule';
import RuleBuilderJustification from './rule-builder-steps/step-5.justification';
import RuleBuilderOverview from './rule-builder-steps/step-6.overview';

const steps = [
  'Define rule detail',
  'Build event pattern',
  'Select target(s)',
  'Configure guaranteed delivery',
  'Provide Business Justification',
  'Review and create',
];

export interface HttpTargetProps {
  endpoint: string;
  httpMethod: string;
  authorizationType:
    | 'API_KEY'
    | 'OAUTH_CLIENT_CREDENTIALS'
    | 'OAUTH_USERNAME_PASSWORD_FLOW';
  apiKeyParams?: {
    apiKeyName: string;
    apiKeyValue: string;
  };
  oauthParams?: {
    authorizationEndpoint: string;
    clientId: string;
    clientSecret: string;
    username: string;
    password: string;
  };
}

export interface RuleBuilderStepProps {
  stepperPayload: RuleStepperPayload;
  setStepperPayload: (stepperPayload: RuleStepperPayload) => void;
  handleBack: () => void;
  handleNext: () => void;
  existingRuleNames?: string[];
  [key: string]: unknown;
}

export default function RuleBuilder({
  setModalStatus,
  existingRuleNames,
}: {
  setModalStatus: (status: boolean) => void;
  existingRuleNames: string[] | undefined;
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [submissionResult, setSubmissionResult] = React.useState<
    | {
        status: 'success' | 'failure';
        message: string;
      }
    | undefined
  >(undefined);

  const bus = useAppSelector<Bus | undefined>((state) => state.alignment.bus);

  const [stepperPayload, setStepperPayload] =
    React.useState<RuleStepperPayload>(
      localStorage.getItem('ruleRecord')
        ? {
            ...JSON.parse(localStorage.getItem('ruleRecord')!),
            ownerAccountName: localStorage.getItem('selectedTenant') as string,
          }
        : {
            eventRuleObject: {
              eventPattern:
                '{"source": [{"prefix": "{MPS-ACCOUNT-ALIAS}/{AWS-REGION}/{APPLICATION-NAME}"}]}',
              eventBusName: bus?.name,
              targets: {
                [v4()]: {
                  targetType: 'HTTP',
                  httpTargetProps: {
                    authorizationType: 'API_KEY',
                    httpMethod: 'GET',
                    endpoint: 'https://',
                  },
                },
              },
            },
            action: 'create',
            ownerAccountName: localStorage.getItem('selectedTenant') as string,
          }
    );

  useEffect(() => {
    if (stepperPayload && bus) {
      stepperPayload.eventRuleObject.eventBusName = bus?.name;
    }
  }, [bus, stepperPayload]);

  const setStepperPayloadWithHistory = (
    newStepperPayload: RuleStepperPayload
  ) => {
    setStepperPayload(newStepperPayload);
    const newStepperPayloadToSave = JSON.parse(
      JSON.stringify(newStepperPayload)
    );
    Object.values<TargetRequest>(
      newStepperPayloadToSave.eventRuleObject.targets
    ).map((target: TargetRequest) => {
      if (target.targetType === 'HTTP') {
        if (target.httpTargetProps?.apiKeyParams) {
          target.httpTargetProps.apiKeyParams.apiKeyValue = '';
        }

        if (target.httpTargetProps?.oauthParams) {
          target.httpTargetProps.oauthParams.clientSecret = '';
          target.httpTargetProps.oauthParams.password = '';
          target.httpTargetProps.oauthParams.username = '';
        }
      }
      return target;
    });
    localStorage.setItem('ruleRecord', JSON.stringify(newStepperPayloadToSave));
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Transform targets Record to targets Array first
      type StepperPayloadFinal = Omit<RuleStepperPayload, 'eventRuleObject'> & {
        eventRuleObject: Omit<
          typeof stepperPayload.eventRuleObject,
          'targets'
        > & {
          targets: TargetRequest[] | Record<string, TargetRequest>;
        };
      };
      const _stepperPayload: StepperPayloadFinal = stepperPayload;
      _stepperPayload.eventRuleObject.targets = Object.values(
        stepperPayload.eventRuleObject.targets
      );
      // Check again if this rule already exists. It could have been created between the first step and now.
      if (
        stepperPayload.eventRuleObject.name &&
        existingRuleNames?.includes(stepperPayload.eventRuleObject.name)
      ) {
        setSubmissionResult({
          status: 'failure',
          message: `A rule with this name already exists for event bus ${stepperPayload.eventRuleObject.eventBusName}. Please choose a different name.`,
        });
        return;
      }
      postEventRuleApplication(_stepperPayload)
        .then((res) => {
          localStorage.removeItem('ruleRecord');
          localStorage.setItem(
            'rule-applications',
            JSON.stringify([
              ...JSON.parse(localStorage.getItem('rule-applications') || '[]'),
              res.id,
            ])
          );
          setSubmissionResult({
            status: 'success',
            message:
              'The team has received your request and will review your application. You will receive all the updates via email.',
          });
        })
        .catch((err: AxiosError) => {
          let detail = '';
          if (err.response && err.response.data) {
            detail = (err.response.data as any).message;
          }
          const message =
            err.response?.status === 401
              ? `${err.message}. It looks like your access token has expired. Your progress is saved (excluding the sensitive data), you can refresh the page and submit it again.`
              : `${err.message}. ${detail}`;
          setSubmissionResult({
            status: 'failure',
            message,
          });
        });
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {submissionResult ? (
        <Box className="stepper-box">
          <Box>
            <Typography variant="h4" component="h4" p="0 0 15px 0">
              {submissionResult.status === 'failure'
                ? 'Request Failed'
                : 'Success'}
            </Typography>
            <Typography variant="body1">{submissionResult.message}</Typography>
          </Box>
          <Box className="control-box">
            <Box className="control-box-separator" />
            <Button onClick={() => setModalStatus(false)}>Close</Button>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h6" component="h4" p="0 0 15px 0">
            Add Rule
          </Typography>
          <Box className="stepper-box">
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Box className="stepper-body">
                {activeStep === 0 ? (
                  <RuleBuilderDetails
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayloadWithHistory}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    existingRuleNames={existingRuleNames}
                  />
                ) : activeStep === 1 ? (
                  <RuleBuilderPatterns
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayloadWithHistory}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 2 ? (
                  <RuleBuilderTargets
                    maxAllowedTargets={5}
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayloadWithHistory}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 3 ? (
                  <RuleSchedule
                    maxAllowedTargets={5}
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayloadWithHistory}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 4 ? (
                  <RuleBuilderJustification
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayloadWithHistory}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : (
                  <RuleBuilderOverview
                    stepperPayload={stepperPayload}
                    setStepperPayload={setStepperPayloadWithHistory}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )}
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </>
  );
}
