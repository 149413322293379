/** @format */

import {
  getAllBuses,
  getBusByName,
  getFailedEventsByRuleAndBus,
  getRulesByBus,
} from '../../api/client';
import { RootState, ThunkAction } from '../store';
import {
  ADD_SCHEMAS_TO_BUS,
  AlignmentActions,
  CHANGE_ALIGNMENT,
  LOAD_BUSSES_REQUEST,
  LOAD_BUSSES_SUCCESS,
  LOAD_BUS_REQUEST,
  LOAD_BUS_RULES_REQUEST,
  LOAD_BUS_RULES_SUCCESS,
  LOAD_BUS_SUCCESS,
  LOAD_RULE_FAILED_EVENTS_REQUEST,
  LOAD_RULE_FAILED_EVENTS_SUCCESS,
} from './actionTypes';

import { Bus } from '../../model/Bus';
import { RequestState } from '../../model/requestState';

export const ChangeAlignment =
  (
    alignment: 'bus' | 'schemas'
  ): ThunkAction<void, RootState, unknown, AlignmentActions> =>
  async (dispatch) => {
    dispatch({ type: CHANGE_ALIGNMENT, alignment });
  };

export const changeBus =
  (
    bus: Bus,
    ownerAccountName: string
  ): ThunkAction<void, RootState, unknown, AlignmentActions> =>
  async (dispatch) => {
    if (!bus) {
      return undefined;
    }
    dispatch({
      type: LOAD_BUS_REQUEST,
      requestStateBusses: RequestState.FETCHING,
    });
    const busResponse = await getBusByName(bus.name, ownerAccountName);
    dispatch({
      type: LOAD_BUS_SUCCESS,
      requestStateBusses: RequestState.SUCCESS,
      bus: busResponse,
    });
    const selectedTenant = window.localStorage.getItem('selectedTenant') ?? '';
    dispatch(loadBusRules(bus.name, selectedTenant));
  };

export const loadBusses =
  (
    ownerAccountName: string
  ): ThunkAction<void, RootState, unknown, AlignmentActions> =>
  async (dispatch) => {
    dispatch({
      type: LOAD_BUSSES_REQUEST,
      requestStateBusses: RequestState.FETCHING,
    });
    const busses = await getAllBuses(ownerAccountName);
    dispatch({
      type: LOAD_BUSSES_SUCCESS,
      busses,
      requestStateBusses: RequestState.SUCCESS,
    });
  };

async function generateHash(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hash));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex.substring(0, 10);
}

export const loadBusRules =
  (
    busName: string,
    ownerAccountName: string
  ): ThunkAction<void, RootState, unknown, AlignmentActions> =>
  async (dispatch) => {
    dispatch({
      type: LOAD_BUS_RULES_REQUEST,
      requestStateRules: RequestState.FETCHING,
    });
    const rules = await getRulesByBus(busName, ownerAccountName);
    const names = rules.map(async (rule) => {
      const hash = await generateHash(`${rule.eventBusName}.${rule.name}`);
      rule.targets.forEach(
        (target) => (target.id = `${hash}-${target.targetName}`)
      );
      return rule;
    });

    const rulesWithId = await Promise.all(names);

    dispatch({
      type: LOAD_BUS_RULES_SUCCESS,
      rules: rulesWithId,
      requestStateRules: RequestState.SUCCESS,
    });
  };

export const loadRuleFailedEvents =
  (
    busName: string,
    ruleName: string
  ): ThunkAction<void, RootState, unknown, AlignmentActions> =>
  async (dispatch) => {
    dispatch({
      type: LOAD_RULE_FAILED_EVENTS_REQUEST,
      requestStateRuleFailedEvents: RequestState.FETCHING,
    });
    const failedEvents = await getFailedEventsByRuleAndBus(busName, ruleName);
    dispatch({
      type: LOAD_RULE_FAILED_EVENTS_SUCCESS,
      failedEvents,
      requestStateRuleFailedEvents: RequestState.SUCCESS,
    });
  };

export const addSchemasToBus =
  (bus: Bus): ThunkAction<void, RootState, unknown, AlignmentActions> =>
  async (dispatch) => {
    dispatch({
      type: ADD_SCHEMAS_TO_BUS,
      bus,
      requestStateRules: RequestState.SUCCESS,
    });
  };
