import { AddCircleOutline, DeleteForever } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';

import { v4 as uuidV4 } from 'uuid';

type Tag = {
  tagKey?: string;
  tagValue?: string;
};

function getTagsFromStringArray(tags: string[]): Record<string, Tag> {
  if (!tags) return {};
  return tags.reduce((previousValue, currentValue) => {
    const [tagKey, tagValue] = currentValue.split(':');
    return {
      ...previousValue,
      ...{
        [uuidV4()]: {
          tagKey,
          tagValue,
        },
      },
    };
  }, {});
}

function convertTagsToStringArray(tags: Record<string, Tag>): string[] {
  return Object.entries(tags).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([tagKey, tagBody]) => `${tagBody.tagKey ?? ''}:${tagBody.tagValue ?? ''}`
  );
}

const CustomTagsComponent = (props: {
  tags: string[];
  children: React.ReactNode[] | React.ReactNode;
  onValueChange: (tags: string[]) => void;
}) => {
  const [tags, setTags] = useState(getTagsFromStringArray(props.tags));

  const onTagKeyChange = (tagKey: string, tagId: string) => {
    tags[tagId].tagKey = tagKey;
    setTags({ ...tags });
    props.onValueChange(convertTagsToStringArray(tags));
  };

  const onTagValueChange = (tagValue: string, tagId: string) => {
    tags[tagId].tagValue = tagValue;
    setTags({ ...tags });
    props.onValueChange(convertTagsToStringArray(tags));
  };

  const onAddTag = () => {
    setTags({
      ...tags,
      [uuidV4()]: {},
    });
    props.onValueChange(convertTagsToStringArray(tags));
  };

  const onDeleteTag = (tagId: string) => {
    delete tags[tagId];
    setTags({ ...tags });
    props.onValueChange(convertTagsToStringArray(tags));
  };

  return (
    <>
      {Object.entries(tags).map(([tagId, tagBody]) => {
        return (
          <Grid container spacing={2} key={tagId}>
            <Grid item xs={5}>
              <TextField
                onChange={(event) => onTagKeyChange(event.target.value, tagId)}
                variant="standard"
                type="text"
                defaultValue={tagBody.tagKey}
                placeholder="Tag Key"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(event) =>
                  onTagValueChange(event.target.value, tagId)
                }
                variant="standard"
                type="text"
                defaultValue={tagBody.tagValue}
                placeholder="Tag Value"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => onDeleteTag(tagId)}
                aria-label="Delete Tag"
              >
                <DeleteForever />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      {props.children}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton onClick={onAddTag} aria-label="add tag" sx={{ mt: 2 }}>
          <AddCircleOutline />
        </IconButton>
      </div>
    </>
  );
};

export default CustomTagsComponent;
