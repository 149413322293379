/** @format */

import React from 'react';
import { Button, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { getStageConfig } from '../../config/portal';

type Props = {
  showIcon?: boolean;
};

const Logout: React.FC<Props> = ({ showIcon = true }) => {
  return (
    <Button
      style={{ textTransform: 'none' }}
      color="inherit"
      href={`${getStageConfig().portalBackendUrl}/auth/logout`}
      startIcon={showIcon ? <LogoutIcon></LogoutIcon> : undefined}
      id="logout"
    >
      <Typography>Logout</Typography>
    </Button>
  );
};

export default Logout;
