import {
  AuthActions,
  FETCH_USER_INFO_FAILED,
  FETCH_USER_INFO_REQUESTED,
  FETCH_USER_INFO_SUCCEEDED,
} from '../actions/actionTypes';

import { RequestState } from '../../model/requestState';

export type UserInfo = {
  email: string | undefined;
  groups: {
    tenantName: string;
    role: string;
  }[];
};

export type AuthState = {
  isError: boolean;
  isAuthenticated: boolean;
  isFetching: boolean;
  requestState: RequestState;
  userinfo?: UserInfo;
};

const initState: AuthState = {
  isError: false,
  requestState: RequestState.INITIAL,
  isAuthenticated: false,
  isFetching: false,
  userinfo: { email: undefined, groups: [] },
};

export default (
  state: AuthState = initState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case FETCH_USER_INFO_REQUESTED:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: true,
        isError: false,
        userinfo: undefined,
        requestState: action.requestState,
      };
    case FETCH_USER_INFO_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        isError: true,
        userinfo: undefined,
        requestState: action.requestState,
      };
    case FETCH_USER_INFO_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        isError: false,
        userinfo: action.userinfo,
        requestState: action.requestState,
      };
    default:
      return state;
  }
};
